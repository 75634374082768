import {
  ExtendedOrganization,
  Organization,
  CqDirectoryEntryData,
  CwOrgData,
} from "../../api/schemas/organization";
import { ExtendedFacility, Facility } from "../../api/schemas/facility";
import { PatientCoverage } from "../../api/schemas/patient";
import {
  getCxData,
  getCoverageAssessment,
  getCqOrganization,
  getCwOrganization,
  getCwFacility,
  getCqFacility,
  getHieEnabledStatus,
} from "../../api/internal";
import { checkMapiAccess } from "../../api/internal";
import { CustomerMapEntry, createCustomerAndRootMaps } from "../../domain/customer";
import { FacilitiesMap } from "../../domain/facility";
import { CxFeatureFlagStatus, CxFeatureFlagDetails } from "../../domain/app-config";
import {
  AppState,
  AppStateLoaded,
  AppStateAction,
  AppStateActionType,
} from "../contexts/app/reducer";
import { captureAndDisplayError } from "./util";
import { Toast } from "./toast";
import { SetStateAction } from "react";

export function baseCxHook({
  state,
  dispatch,
  cxId,
  setCustomer,
  setIsRoot,
  setCwStatus,
  setCqStatus,
  setEpicStatus,
  setDemoAugStatus,
  setHasMapiAccess,
  toast,
}: {
  state: AppStateLoaded;
  dispatch: React.Dispatch<AppStateAction>;
  cxId: string;
  setCustomer: React.Dispatch<SetStateAction<CustomerMapEntry | undefined>>;
  setIsRoot: React.Dispatch<SetStateAction<boolean | undefined>>;
  setCwStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  setCqStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  setEpicStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  setDemoAugStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  setHasMapiAccess?: React.Dispatch<SetStateAction<boolean | undefined>>;
  toast: Toast;
}) {
  const customerInState = (state.customerMap ?? {})[cxId];
  if (customerInState !== undefined) {
    setCustomer(customerInState);
  }
  const isRootInState = (state.rootMap ?? {})[cxId];
  if (isRootInState !== undefined) {
    setIsRoot(isRootInState);
  }
  let isCwInState: CxFeatureFlagDetails | undefined;
  if (setCwStatus !== undefined) {
    isCwInState = (state.cwStatusMap ?? {})[cxId];
    if (isCwInState !== undefined) {
      setCwStatus(isCwInState);
    }
  }
  let isCqInState: CxFeatureFlagDetails | undefined;
  if (setCqStatus !== undefined) {
    isCqInState = (state.cqStatusMap ?? {})[cxId];
    if (isCqInState !== undefined) {
      setCqStatus(isCqInState);
    }
  }
  let isEpicInState: CxFeatureFlagDetails | undefined;
  if (setEpicStatus !== undefined) {
    isEpicInState = (state.epicStatusMap ?? {})[cxId];
    if (isEpicInState !== undefined) {
      setEpicStatus(isEpicInState);
    }
  }
  let isDemoAugInState: CxFeatureFlagDetails | undefined;
  if (setDemoAugStatus !== undefined) {
    isDemoAugInState = (state.demoAugStatusMap ?? {})[cxId];
    if (isDemoAugInState !== undefined) {
      setDemoAugStatus(isDemoAugInState);
    }
  }
  if (
    ((setCwStatus !== undefined && isCwInState === undefined) ||
      (setCqStatus !== undefined && isCqInState === undefined) ||
      (setEpicStatus !== undefined && isEpicInState === undefined) ||
      (setDemoAugStatus !== undefined && isDemoAugInState === undefined)) &&
    state.userRoles.length > 0
  ) {
    fetchAndSetCxHieEnabledStatus({
      cxId,
      state,
      dispatch,
      setCwStatus,
      setCqStatus,
      setEpicStatus,
      setDemoAugStatus,
      toast,
    });
  }
  if (setHasMapiAccess !== undefined) {
    const hasMapiAccessInState = (state.mapiAccessMap ?? {})[cxId];
    if (hasMapiAccessInState !== undefined) {
      setHasMapiAccess(hasMapiAccessInState);
    } else if (state.userRoles.length > 0) {
      fetchAndSetMapiAccess({
        cxId,
        state,
        dispatch,
        setter: setHasMapiAccess,
        toast,
      });
    }
  }
}

export function cxDataHook({
  state,
  dispatch,
  cxId,
  setOrganization,
  setFacilities,
  toast,
  hideHieOrgDisplay,
}: {
  state: AppStateLoaded;
  dispatch: React.Dispatch<AppStateAction>;
  cxId: string;
  setOrganization: React.Dispatch<SetStateAction<ExtendedOrganization | undefined>>;
  setFacilities?: React.Dispatch<SetStateAction<FacilitiesMap | undefined>>;
  toast: Toast;
  hideHieOrgDisplay?: boolean;
}) {
  let fetchRan = false;
  const orgInState = (state.organizationMap ?? {})[cxId];
  if (setOrganization && orgInState !== undefined) {
    setOrganization(orgInState);
  } else if (state.userRoles.length > 0) {
    fetchAndSetCxData({
      cxId,
      state,
      dispatch,
      orgSetter: setOrganization,
      facilitiesSetter: setFacilities,
      toast,
      hideHieOrgDisplay,
    });
    fetchRan = true;
  }
  if (setFacilities !== undefined) {
    const faciliitiesMapInState = (state.facilityMap ?? {})[cxId];
    if (faciliitiesMapInState !== undefined) {
      setFacilities(faciliitiesMapInState);
    } else if (state.userRoles.length > 0 && !fetchRan) {
      fetchAndSetCxData({
        cxId,
        state,
        dispatch,
        orgSetter: setOrganization,
        facilitiesSetter: setFacilities,
        toast,
        hideHieOrgDisplay,
      });
    }
  }
}

export function facilityDataHook({
  state,
  dispatch,
  cxId,
  setFacilities,
  facilityId,
  toast,
}: {
  state: AppStateLoaded;
  dispatch: React.Dispatch<AppStateAction>;
  cxId: string;
  setFacilities: React.Dispatch<SetStateAction<FacilitiesMap | undefined>>;
  facilityId: string;
  toast: Toast;
}) {
  const faciliitiesMapInState = (state.facilityMap ?? {})[cxId];
  if (faciliitiesMapInState !== undefined) {
    const facilityInState = faciliitiesMapInState[facilityId];
    if (
      facilityInState &&
      facilityInState.cwFacility !== undefined &&
      facilityInState.cqFacility !== undefined
    ) {
      setFacilities(faciliitiesMapInState);
    } else if (facilityInState && state.userRoles.length > 0) {
      fetchAndSetCxFacilityData({
        cxId,
        state,
        dispatch,
        facilitiesSetter: setFacilities,
        facility: facilityInState.facility,
        facilities: Object.values(faciliitiesMapInState).filter(
          f => f !== undefined
        ) as ExtendedFacility[],
        toast,
      });
    }
  }
}

export function facilityPatientDataHook({
  state,
  dispatch,
  cxId,
  setFacilities,
  facilityId,
  toast,
}: {
  state: AppStateLoaded;
  dispatch: React.Dispatch<AppStateAction>;
  cxId: string;
  setFacilities: React.Dispatch<SetStateAction<FacilitiesMap | undefined>>;
  facilityId: string;
  toast: Toast;
}) {
  const faciliitiesMapInState = (state.facilityMap ?? {})[cxId];
  if (faciliitiesMapInState !== undefined) {
    const facilityInState = faciliitiesMapInState[facilityId];
    if (facilityInState && state.userRoles.length > 0) {
      fetchAndSetCxFacilityPatientData({
        cxId,
        state,
        dispatch,
        facilitiesSetter: setFacilities,
        facility: facilityInState,
        facilities: Object.values(faciliitiesMapInState).filter(
          f => f !== undefined
        ) as ExtendedFacility[],
        toast,
      });
    }
  }
}

export function setCxOrganzation({
  cxId,
  state,
  dispatch,
  setter,
  organization,
  cqOrganization,
  cwOrganization,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  setter: React.Dispatch<SetStateAction<ExtendedOrganization | undefined>>;
  organization: Organization | null;
  cqOrganization?: CqDirectoryEntryData | null;
  cwOrganization?: CwOrgData | null;
}): void {
  const extendedOrg = {
    organization,
    cqOrganization,
    cwOrganization,
  };
  dispatch({
    type: AppStateActionType.update,
    newState: {
      ...state,
      organizationMap: {
        ...state.organizationMap,
        [cxId]: extendedOrg,
      },
    },
  });
  setter(extendedOrg);
}

export function setCxFacility({
  cxId,
  state,
  dispatch,
  setter,
  facility,
  cqFacility,
  cwFacility,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  setter: React.Dispatch<SetStateAction<Facility | undefined>>;
  facility: Facility;
  cqFacility?: CqDirectoryEntryData | null;
  cwFacility?: CwOrgData | null;
}): void {
  const cxFacilitiesMap = (state.facilityMap ?? {})[cxId];
  const extendedFacility = {
    facility,
    cqFacility,
    cwFacility,
  };
  dispatch({
    type: AppStateActionType.update,
    newState: {
      ...state,
      facilityMap: {
        ...state.facilityMap,
        [cxId]: {
          ...cxFacilitiesMap,
          [facility.id]: extendedFacility,
        },
      },
    },
  });
  setter(facility);
}

export function setCxFacilities({
  cxId,
  state,
  dispatch,
  setter,
  facilities,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  setter?: React.Dispatch<SetStateAction<FacilitiesMap | undefined>>;
  facilities: ExtendedFacility[];
}): void {
  const cxFacilitiesMap = Object.fromEntries(
    facilities.map(facility => [facility.facility.id, facility])
  );
  dispatch({
    type: AppStateActionType.update,
    newState: {
      ...state,
      facilityMap: {
        ...state.facilityMap,
        [cxId]: cxFacilitiesMap,
      },
    },
  });
  if (setter) setter(cxFacilitiesMap);
}

async function fetchAndSetCxFacilityData({
  cxId,
  state,
  dispatch,
  facilitiesSetter,
  facility,
  facilities,
  toast,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  facilitiesSetter?: React.Dispatch<SetStateAction<FacilitiesMap | undefined>>;
  facility: Facility;
  facilities: ExtendedFacility[];
  toast: Toast;
}): Promise<void> {
  let cqFacility = null;
  let cwFacility = null;
  try {
    cqFacility = await getCqFacility(cxId, facility.id, facility.oid);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status !== 404) {
      captureAndDisplayError({
        error,
        msg: "Failed to get CQ organization for Metriport facility",
        context: "fetchAndSetCxFacilityData",
        captureMsg: "getCqFacility failed",
        toast,
      });
    }
  }
  try {
    cwFacility = await getCwFacility(cxId, facility.id, facility.oid);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status !== 404) {
      captureAndDisplayError({
        error,
        msg: "Failed to get CW organization for Metriport facility",
        context: "fetchAndSetCxFacilityData",
        captureMsg: "getCwFacility failed",
        toast,
      });
    }
  }
  setCxFacilities({
    cxId,
    state,
    dispatch,
    setter: facilitiesSetter,
    facilities: [
      ...facilities.filter(f => f !== undefined && f.facility.id !== facility.id),
      {
        facility,
        cqFacility,
        cwFacility,
      },
    ],
  });
}

async function fetchAndSetCxFacilityPatientData({
  cxId,
  state,
  dispatch,
  facilitiesSetter,
  facility,
  facilities,
  toast,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  facilitiesSetter?: React.Dispatch<SetStateAction<FacilitiesMap | undefined>>;
  facility: ExtendedFacility;
  facilities: ExtendedFacility[];
  toast: Toast;
}): Promise<void> {
  let patientsWithAssessments: PatientCoverage[] = [];
  try {
    const resp = await getCoverageAssessment({ cxId, facilityId: facility.facility.id });
    patientsWithAssessments = resp.patientsWithAssessments;
  } catch (error) {
    captureAndDisplayError({
      error,
      msg: "Failed to get facility patients",
      context: "fetchAndSetCxFacilityPatientData",
      captureMsg: "getCoverageAssessment failed",
      toast,
    });
  }
  setCxFacilities({
    cxId,
    state,
    dispatch,
    setter: facilitiesSetter,
    facilities: [
      ...facilities.filter(f => f !== undefined && f.facility.id !== facility.facility.id),
      {
        ...facility,
        patientsWithAssessments,
      },
    ],
  });
}

async function fetchAndSetCxData({
  cxId,
  state,
  dispatch,
  orgSetter,
  facilitiesSetter,
  toast,
  hideHieOrgDisplay = false,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  orgSetter: React.Dispatch<SetStateAction<ExtendedOrganization | undefined>>;
  facilitiesSetter?: React.Dispatch<SetStateAction<FacilitiesMap | undefined>>;
  toast: Toast;
  hideHieOrgDisplay?: boolean;
}): Promise<void> {
  let organization = null;
  let facilities: Facility[] = [];
  let cqOrganization = null;
  let cwOrganization = null;
  try {
    const result = await getCxData(cxId);
    organization = result.org;
    facilities = result.facilities;
    if (organization && organization.businessType === "healthcare_provider") {
      try {
        cqOrganization = await getCqOrganization(cxId, organization.oid);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.response.status !== 404) {
          captureAndDisplayError({
            error,
            msg: "Failed to get CQ organization for Metriport organization",
            context: "fetchAndSetCxData",
            captureMsg: "getCqOrganization failed",
            toast,
            hideDisplay: hideHieOrgDisplay,
          });
        }
      }
      try {
        cwOrganization = await getCwOrganization(cxId, organization.oid);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.response.status !== 404) {
          captureAndDisplayError({
            error,
            msg: "Failed to get CW organization for Metriport organization",
            context: "fetchAndSetCxData",
            captureMsg: "getCwOrganization failed",
            toast,
            hideDisplay: hideHieOrgDisplay,
          });
        }
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status !== 404) {
      captureAndDisplayError({
        error,
        msg: "Failed to get cx data",
        context: "fetchAndSetCxData",
        captureMsg: "getCxData failed",
        toast,
      });
    }
  }
  setCxOrganzation({
    cxId,
    state,
    dispatch,
    setter: orgSetter,
    organization,
    cqOrganization,
    cwOrganization,
  });
  setCxFacilities({
    cxId,
    state,
    dispatch,
    setter: facilitiesSetter,
    facilities: facilities.map(facility => {
      return { facility };
    }),
  });
}

export function setCxMapiAccess({
  cxId,
  state,
  dispatch,
  setter,
  hasMapiAccess,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  setter?: React.Dispatch<SetStateAction<boolean | undefined>>;
  hasMapiAccess: boolean;
}): void {
  dispatch({
    type: AppStateActionType.update,
    newState: {
      ...state,
      mapiAccessMap: {
        ...state.mapiAccessMap,
        [cxId]: hasMapiAccess,
      },
    },
  });
  if (setter) setter(hasMapiAccess);
}

async function fetchAndSetMapiAccess({
  cxId,
  state,
  dispatch,
  setter,
  toast,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  setter: React.Dispatch<SetStateAction<boolean | undefined>>;
  toast: Toast;
}): Promise<void> {
  try {
    const result = await checkMapiAccess(cxId);
    const hasMapiAccess = result.hasMapiAccess;
    setCxMapiAccess({
      cxId,
      state,
      dispatch,
      setter,
      hasMapiAccess,
    });
  } catch (error) {
    captureAndDisplayError({
      error,
      msg: "Failed to get customer mapi access result",
      context: "fetchAndSetMapiAccess",
      captureMsg: "checkMapiAccess failed",
      toast,
    });
  }
}

export function setCxIsRoot({
  cxId,
  state,
  dispatch,
  setter,
  isRoot,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  setter?: React.Dispatch<SetStateAction<boolean | undefined>>;
  isRoot: boolean;
}): void {
  const newCustomers = (state.rawCustomers ?? []).map(cx => {
    if (cx.id === cxId) {
      return {
        ...cx,
        isRoot,
      };
    }
    return cx;
  });
  const { customerMap: newCustomerMap, rootMap: newRootMap } =
    createCustomerAndRootMaps(newCustomers);
  dispatch({
    type: AppStateActionType.update,
    newState: {
      ...state,
      rootMap: newRootMap,
      customerMap: newCustomerMap,
    },
  });
  if (setter) setter(isRoot);
}

export function setCxHieEnabledStatus({
  cxId,
  state,
  dispatch,
  setCwStatus,
  setCqStatus,
  setEpicStatus,
  setDemoAugStatus,
  hieEnabledStatus,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  setCwStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  setCqStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  setEpicStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  setDemoAugStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  hieEnabledStatus: CxFeatureFlagStatus;
}): void {
  const cwEnabled = hieEnabledStatus.cxsWithCWFeatureFlag;
  const cqEnabled = hieEnabledStatus.cxsWithCQDirectFeatureFlag;
  const epicEnabled = hieEnabledStatus.cxsWithEpicEnabled;
  const demoAugEnabled = hieEnabledStatus.cxsWithDemoAugEnabled;
  dispatch({
    type: AppStateActionType.update,
    newState: {
      ...state,
      cqStatusMap: {
        ...state.cqStatusMap,
        [cxId]: cqEnabled,
      },
      cwStatusMap: {
        ...state.cwStatusMap,
        [cxId]: cwEnabled,
      },
      epicStatusMap: {
        ...state.epicStatusMap,
        [cxId]: epicEnabled,
      },
      demoAugStatusMap: {
        ...state.demoAugStatusMap,
        [cxId]: demoAugEnabled,
      },
    },
  });
  if (setCwStatus) setCwStatus(cwEnabled);
  if (setCqStatus) setCqStatus(cqEnabled);
  if (setEpicStatus) setEpicStatus(epicEnabled);
  if (setDemoAugStatus) setDemoAugStatus(demoAugEnabled);
}

async function fetchAndSetCxHieEnabledStatus({
  cxId,
  state,
  dispatch,
  setCwStatus,
  setCqStatus,
  setEpicStatus,
  setDemoAugStatus,
  toast,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  setCwStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  setCqStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  setEpicStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  setDemoAugStatus?: React.Dispatch<SetStateAction<CxFeatureFlagDetails | undefined>>;
  toast: Toast;
}): Promise<void> {
  try {
    const hieEnabledStatus = await getHieEnabledStatus(cxId);
    setCxHieEnabledStatus({
      cxId,
      state,
      dispatch,
      setCwStatus,
      setCqStatus,
      setEpicStatus,
      setDemoAugStatus,
      hieEnabledStatus,
    });
  } catch (error) {
    captureAndDisplayError({
      error,
      msg: "Failed to get customer hie statuses",
      context: "fetchAndSetCxHieEnabledStatus",
      captureMsg: "getHieEnabledStatus failed",
      toast,
    });
  }
}
