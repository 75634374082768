import { jwtDecode } from "jwt-decode";
import { intersection } from "lodash";

export const roleSuperAdmin = "super-admin";
export const roleAdmin = "admin";
export const roleContributer = "contributor";

const rolesOnJWT = "cognito:groups";

export function getRoles(jwtStr: string): string[] {
  if (!jwtStr || jwtStr.trim().length <= 0) return [];
  const token = jwtDecode(jwtStr);
  if (!token) return [];
  // Unfortunately jwtDecode's response type doesn't include custom attributes
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const roles = (token as any)[rolesOnJWT];
  if (roles == undefined) return [];
  if (typeof roles === "string") return JSON.parse(roles);
  return roles;
}

export function isRoleIncluded(role: string, roles: string[] | undefined): boolean {
  if (!roles) return false;
  return roles.includes(role);
}

export function areRolesIncluded(roles: string[], userRoles: string[] | undefined): boolean {
  if (!roles || roles.length <= 0) return false;
  if (!userRoles || userRoles.length <= 0) return false;
  return intersection(roles, userRoles).length >= roles.length;
}
