import { AmplifyUser } from "@aws-amplify/ui";
import { RawCustommers, CustomerMap, RootMap, MapiAccessMap } from "../../../domain/customer";
import { OrganizationMap } from "../../../domain/organization";
import { FacilityMap } from "../../../domain/facility";
import { FeatureFlagMap } from "../../../domain/app-config";

type BaseAppState = {
  authToken?: string;
  user?: AmplifyUser;
  userRoles?: string[];
  rawCustomers?: RawCustommers;
  customerMap?: CustomerMap;
  rootMap?: RootMap;
  mapiAccessMap?: MapiAccessMap;
  cwStatusMap?: FeatureFlagMap;
  cqStatusMap?: FeatureFlagMap;
  epicStatusMap?: FeatureFlagMap;
  demoAugStatusMap?: FeatureFlagMap;
  organizationMap?: OrganizationMap;
  facilityMap?: FacilityMap;
};

export type AppStateLoaded = Required<Pick<BaseAppState, "authToken" | "user" | "userRoles">> &
  BaseAppState & { isLoaded: true };

export type AppState = (BaseAppState & { isLoaded: false }) | AppStateLoaded;

export enum AppStateActionType {
  update,
}

export interface AppStateAction {
  type: AppStateActionType;
  newState: BaseAppState | AppState;
}

export const reducer = (state: AppState, action: AppStateAction) => {
  switch (action.type) {
    case AppStateActionType.update:
      return {
        ...state,
        ...action.newState,
      };

    default:
      return state;
  }
};

export const initialState: AppState = {
  isLoaded: false,
};
