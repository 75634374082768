import { Input as BaseInput } from "@chakra-ui/react";

export const Input = ({
  id,
  onChange,
  color = "blue",
  css = {},
}: {
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: HTMLInputElement;
  color?: string;
  css?: object;
}) => {
  return (
    <BaseInput
      onChange={onChange}
      h={10}
      p={2}
      m={1}
      key={id}
      variant="solid"
      colorScheme={color}
      {...css}
    />
  );
};
