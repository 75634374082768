import { Customer } from "../api/schemas/customer";

export const metriportDomain = "metriport.com";

export type RawCustommers = Customer[];

export type CustomerMapEntry = {
  rootCustomer: Customer;
  connectedEmails: string[];
};

export type CustomerMap = { [key: string]: CustomerMapEntry | undefined };
export type RootMap = { [key: string]: boolean | undefined };
export type MapiAccessMap = { [key: string]: boolean | undefined };

function getEmailDomain(email: string): string {
  return email.split("@")[1];
}

export function createCustomerAndRootMaps(customers: Customer[]): {
  customerMap: CustomerMap;
  rootMap: RootMap;
} {
  const customerMap: CustomerMap = {};
  const customerCoreEmailDomainMap: { [key: string]: string | undefined } = {};
  customers
    .filter(c => c.isRoot)
    .map(customer => {
      if (customer.email === null) return;
      customerMap[customer.id] = { rootCustomer: customer, connectedEmails: [] };
      customerCoreEmailDomainMap[getEmailDomain(customer.email)] = customer.id;
    });
  customers
    .filter(c => !c.isRoot)
    .map(c => {
      if (c.email === null) return;
      const domain = getEmailDomain(c.email);
      if (domain === metriportDomain) {
        customerMap[c.id] = { rootCustomer: c, connectedEmails: [] };
        return;
      }
      const coreCxId = customerCoreEmailDomainMap[domain];
      if (!coreCxId || !customerMap[coreCxId]) {
        customerMap[c.id] = { rootCustomer: c, connectedEmails: [] };
      } else {
        customerMap[coreCxId]?.connectedEmails.push(c.email);
      }
    });
  const rootMap = Object.fromEntries(customers.map(customer => [customer.id, customer.isRoot]));
  return { customerMap, rootMap };
}
