import { sortBy } from "lodash";
import { states, territories } from "@metriport/shared";
import { OrganizationBizType, TreatmentType } from "../../domain/organization";
import { FacilityType } from "../../domain/facility";

/**
 * @deprecated Move this to @metriport/shared
 */
class Constants {
  static readonly supportEmail = "support@metriport.com";
  static readonly reachoutToUs = `Please try again later or reachout to us on ${Constants.supportEmail}`;
  static readonly errorDefaultMessage = `Something went wrong. ${Constants.reachoutToUs}`;
  static booleanAndUndefinedOptions = [
    { label: "True", value: "true" },
    { label: "False", value: "false" },
    { label: "Do not edit", value: "undefined" },
  ];
  static facilityOptions = [
    { label: "OBO", value: "OBO" },
    { label: "Non-OBO", value: "Non-OBO" },
  ];
  static facilityTypes: { [k in FacilityType]: string } = {
    [FacilityType.initiatorOnly]: "Initiator Only",
    [FacilityType.initiatorAndResponder]: "Initiator and Responder",
  };
  static orgBizTypeOptions = [
    { label: "IT Vendor", value: "IT Vendor" },
    { label: "Provider", value: "Provider" },
  ];
  static orgBizTypes: { [k in OrganizationBizType]: string } = {
    [OrganizationBizType.healthcareITVendor]: "IT Vendor",
    [OrganizationBizType.healthcareProvider]: "Provider",
  };
  static treatmentTypes: { [k in TreatmentType]: string } = {
    [TreatmentType.acuteCare]: "Acute Care",
    [TreatmentType.ambulatory]: "Ambulatory",
    [TreatmentType.hospital]: "Hospital",
    [TreatmentType.labSystems]: "Lab Systems",
    [TreatmentType.pharmacy]: "Pharmacy",
    [TreatmentType.postAcuteCare]: "Post Acute Care",
  };
  static usStates = Object.entries(states).map(([key, value]) => ({ label: value, value: key }));
  static usTerritories = Object.entries(territories).map(([key, value]) => ({
    label: value,
    value: key,
  }));
  static usStatesForAddress = sortBy(
    Constants.usStates.concat(Constants.usTerritories),
    state => state.label
  );
  static usStatesForDriverLicense = Constants.usStates;
}

export default Constants;
