import { useState } from "react";
import { Heading } from "@chakra-ui/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { get } from "lodash";
import { Box } from "@chakra-ui/react";
import {
  Organization,
  organizationCreateOrUpdateInternalSchema,
} from "../../../api/schemas/organization";
import { booleanOrUndefinedSchema } from "../../../api/schemas/shared";
import { OrganizationBizType } from "../../../domain/organization";
import Constants from "../../shared/constants";
import DrawerForm from "../../shared/form/Drawer";
import { Input } from "../../shared/form/Input";
import { Select } from "../../shared/form/Select";
import useMetriportToast from "../../shared/toast";
import { mapToOptions, captureAndDisplayError } from "../../shared/util";
import { createOrUpdateOrganization } from "../../../api/internal";

const formSchema = organizationCreateOrUpdateInternalSchema
  .omit({
    id: true,
    cqApproved: true,
    cqActive: true,
    cwApproved: true,
    cwActive: true,
  })
  .merge(
    z.object({
      cqApproved: booleanOrUndefinedSchema,
      cqActive: booleanOrUndefinedSchema,
      cwApproved: booleanOrUndefinedSchema,
      cwActive: booleanOrUndefinedSchema,
    })
  );
type FormData = z.infer<typeof formSchema>;

export default function OrganizationInternalForm({
  isOpen,
  onClose,
  onSubmit,
  cxId,
  currentOrg,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (cxId: string, newOrganization: Organization) => void;
  cxId: string;
  currentOrg: Organization | undefined;
}) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const toast = useMetriportToast();

  const [isMapi, setIsMapi] = useState<boolean>(
    currentOrg ? currentOrg.businessType === OrganizationBizType.healthcareITVendor : false
  );

  const createOrUpdate = async (data: FormData) => {
    try {
      setIsSubmitting(true);
      let newOrganization: Organization;
      const adjustedParams = {
        cqActive: isMapi
          ? undefined
          : data.cqActive !== "undefined"
          ? data.cqActive === "true"
          : undefined,
        cwActive: isMapi
          ? undefined
          : data.cwActive !== "undefined"
          ? data.cwActive === "true"
          : undefined,
        cqApproved: isMapi
          ? undefined
          : data.cqApproved !== "undefined"
          ? data.cqApproved === "true"
          : undefined,
        cwApproved: isMapi
          ? undefined
          : data.cwApproved !== "undefined"
          ? data.cwApproved === "true"
          : undefined,
        businessType: isMapi
          ? OrganizationBizType.healthcareITVendor
          : OrganizationBizType.healthcareProvider,
      };
      if (currentOrg) {
        newOrganization = await createOrUpdateOrganization(cxId, {
          ...{
            ...currentOrg,
            location: undefined,
          },
          ...currentOrg.location,
          ...data,
          ...adjustedParams,
        });
      } else {
        newOrganization = await createOrUpdateOrganization(cxId, {
          ...data,
          ...adjustedParams,
        });
      }
      toast.success({
        duration: 10_000,
        title: `Organization saved.${
          adjustedParams.cqApproved || adjustedParams.cwApproved
            ? ` You have an HIE Approved. Please reload the page to see the new HIE facilities. It may take more than 30 seconds.`
            : ""
        }`,
      });
      onSubmit(cxId, newOrganization);
    } catch (error) {
      // TODO Check for timeout and flag as warning (#1940)
      captureAndDisplayError({
        error,
        msg: "Failed to save organization",
        context: "createOrUpdate",
        captureMsg: `Component: OrganizationInternalForm - ${
          currentOrg ? "update" : "create"
        } failed`,
        toast,
        extras: {
          cxId,
          orgId: currentOrg?.id,
        },
      });
    }
    setIsSubmitting(false);
  };

  return (
    <DrawerForm<FormData>
      title={`${currentOrg ? "Edit" : "Create"} Organization`}
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onSubmit={createOrUpdate}
      onClose={onClose}
      resolver={zodResolver(formSchema)}
      defaultValues={{
        nameInMetriport: currentOrg?.name,
        type: currentOrg?.type,
        addressLine1: currentOrg?.location.addressLine1,
        addressLine2: currentOrg?.location.addressLine2,
        city: currentOrg?.location.city,
        state: currentOrg?.location.state,
        zip: currentOrg?.location.zip,
        country: currentOrg?.location.country,
      }}
    >
      {({ register, reset, formState: { errors } }) => (
        <>
          <Heading as="h4" size="sm" mb={5}>
            Organization Business Type
          </Heading>
          <Select
            isRequired
            options={Constants.orgBizTypeOptions}
            value={isMapi ? "IT Vendor" : "Provider"}
            onChange={change => {
              setIsMapi(change.target.value === "IT Vendor");
              reset();
            }}
            mb={12}
            isDisabled={!!currentOrg}
          />
          <Heading as="h4" size="sm" mb={5}>
            Basic Info
          </Heading>
          <Input
            {...register("nameInMetriport")}
            isRequired
            label="Name in Metriport *"
            error={get(errors, "nameInMetriport")}
          />
          <Input
            {...register("businessType")}
            isRequired
            isReadOnly
            label="Business Type"
            value={isMapi ? "healthcare_it_vendor" : "healthcare_provider"}
            error={get(errors, "businessType")}
          />
          <Select
            {...register("type")}
            isRequired
            label="Treatment Type *"
            options={mapToOptions(Constants.treatmentTypes)}
            placeholder="Select Type"
            error={get(errors, "type")}
          />
          <Box hidden={isMapi}>
            <Select
              {...register("cqApproved")}
              label="CQ Approved"
              defaultValue={
                currentOrg?.cqApproved !== undefined && currentOrg?.cqApproved !== null
                  ? `${currentOrg?.cqApproved}`
                  : "false"
              }
              options={Constants.booleanAndUndefinedOptions}
              error={get(errors, "cqApproved")}
            />
            <Select
              {...register("cqActive")}
              label="CQ Active"
              defaultValue={
                currentOrg?.cqActive !== undefined && currentOrg?.cqActive !== null
                  ? `${currentOrg?.cqActive}`
                  : "false"
              }
              options={Constants.booleanAndUndefinedOptions}
              error={get(errors, "cqActive")}
            />
            <Select
              {...register("cwApproved")}
              label="CW Approved"
              defaultValue={
                currentOrg?.cwApproved !== undefined && currentOrg?.cwApproved !== null
                  ? `${currentOrg?.cwApproved}`
                  : "false"
              }
              options={Constants.booleanAndUndefinedOptions}
              error={get(errors, "cwApproved")}
            />
            <Select
              {...register("cwActive")}
              label="CW Active"
              defaultValue={
                currentOrg?.cwActive !== undefined && currentOrg?.cwActive !== null
                  ? `${currentOrg?.cwActive}`
                  : "false"
              }
              options={Constants.booleanAndUndefinedOptions}
              error={get(errors, "cwActive")}
            />
          </Box>
          <Heading as="h4" size="sm" pt={50}>
            Address
          </Heading>
          <Input
            {...register("addressLine1")}
            isRequired
            label="Address Line 1 *"
            error={get(errors, "addressLine1")}
          />
          <Input
            {...register("addressLine2")}
            label="Address Line 2"
            error={get(errors, "addressLine2")}
          />
          <Input {...register("city")} isRequired label="City *" error={get(errors, "city")} />
          <Select
            {...register("state")}
            isRequired
            label="State *"
            options={Constants.usStatesForAddress}
            placeholder="Select State"
            error={get(errors, "state")}
          />
          <Input {...register("zip")} isRequired label="Zip *" error={get(errors, "zip")} />
          <Input
            {...register("country")}
            isRequired
            isReadOnly
            color={"grey"}
            label="Country *"
            value={"USA"}
            error={get(errors, "country")}
          />
        </>
      )}
    </DrawerForm>
  );
}
