import { Button as BaseButton, Text, TagRightIcon, TagLeftIcon } from "@chakra-ui/react";
import {
  CheckIcon,
  WarningIcon,
  EmailIcon,
  InfoOutlineIcon,
  EditIcon,
  DeleteIcon,
  AddIcon,
  ArrowRightIcon,
  SpinnerIcon,
} from "@chakra-ui/icons";
import { Spinner } from "@chakra-ui/react";
import useMetriportToast from "../shared/toast";

const tagMap = {
  check: CheckIcon,
  warning: WarningIcon,
  email: EmailIcon,
  info: InfoOutlineIcon,
  add: AddIcon,
  edit: EditIcon,
  delete: DeleteIcon,
  arrowRight: ArrowRightIcon,
  load: SpinnerIcon,
  spinner: Spinner,
};

export const Button = ({
  id,
  onClick,
  label,
  icon,
  iconSide = "right",
  color = "blue",
  css = {},
}: {
  id: string;
  onClick?: () => void;
  label: string | React.ReactNode;
  icon?: keyof typeof tagMap;
  iconSide?: "left" | "right";
  color?: string;
  css?: object;
}) => {
  return (
    <BaseButton
      onClick={onClick}
      h={10}
      p={2}
      m={1}
      key={id}
      variant="solid"
      colorScheme={color}
      {...css}
    >
      {icon && iconSide == "left" && <TagLeftIcon as={tagMap[icon]} />}
      {typeof label === "string" ? <Text fontSize={"sm"}>{label}</Text> : label}
      {icon && iconSide == "right" && <TagRightIcon as={tagMap[icon]} />}
    </BaseButton>
  );
};

export const ButtonBoolean = ({
  id,
  onClick,
  disableOnClick = true,
  label,
  check,
  icon,
  iconFalse,
  iconSide = "right",
  activeColor = "blue",
  nonActiveColor = "red",
  failedCheckMessage,
  css = {},
}: {
  id: string;
  onClick?: () => void;
  disableOnClick?: boolean;
  label: string | React.ReactNode;
  check: boolean;
  icon?: keyof typeof tagMap;
  iconFalse?: keyof typeof tagMap;
  iconSide?: "left" | "right";
  activeColor?: string;
  nonActiveColor?: string;
  failedCheckMessage?: string;
  css?: object;
}) => {
  const toast = useMetriportToast();
  const color = check ? activeColor : nonActiveColor;
  const usedIcon = check
    ? icon
      ? tagMap[icon]
      : CheckIcon
    : iconFalse
    ? tagMap[iconFalse]
    : WarningIcon;
  return (
    <BaseButton
      onClick={
        disableOnClick && !check
          ? () => {
              toast.error({ title: failedCheckMessage });
            }
          : onClick
      }
      h={10}
      p={2}
      m={1}
      key={id}
      variant="solid"
      colorScheme={color}
      {...css}
    >
      {iconSide == "left" && <TagLeftIcon as={usedIcon} />}
      {typeof label === "string" ? <Text fontSize={"sm"}>{label}</Text> : label}
      {iconSide == "right" && <TagRightIcon as={usedIcon} />}
    </BaseButton>
  );
};
