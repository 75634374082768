import { api } from "./api";
import { customerSchema, Customer } from "./schemas/customer";

const CUSTOMERS_URL = `/customer`;

export async function getCustomers(): Promise<Customer[]> {
  const resp = await api.get(CUSTOMERS_URL);
  if (!resp.data) throw new Error(`Customers not returned`);
  return resp.data.map(customerSchema.parse);
}

export async function getCustomer(cxId: string): Promise<Customer> {
  const resp = await api.get(`${CUSTOMERS_URL}/${cxId}`);
  if (!resp.data) throw new Error(`Customer not returned`);
  return customerSchema.parse(resp.data);
}

export async function assignRoot(cxId: string): Promise<Customer> {
  const resp = await api.post(`${CUSTOMERS_URL}/${cxId}/root`);
  if (!resp.data) throw new Error(`Customer not returned`);
  return customerSchema.parse(resp.data);
}

export async function unassignRoot(cxId: string): Promise<Customer> {
  const resp = await api.delete(`${CUSTOMERS_URL}/${cxId}/root`);
  if (!resp.data) throw new Error(`Customer not returned`);
  return customerSchema.parse(resp.data);
}
