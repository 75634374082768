import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  VStack,
  Text,
  useDisclosure,
  Alert,
  AlertTitle,
  Skeleton,
} from "@chakra-ui/react";
import { useAppContext } from "../contexts/app/index";
import { assignRoot, unassignRoot } from "../../api/customer";
import { allowMapiAccess, revokeMapiAccess } from "../../api/internal";
import {
  Organization,
  ExtendedOrganization,
  ExtendedOrganizationWithMapiOrg,
} from "../../api/schemas/organization";
import { CustomerMapEntry } from "../../domain/customer";
import { CxFeatureFlagDetails, CxFeatureFlagStatus } from "../../domain/app-config";
import { roleAdmin, areRolesIncluded } from "../../shared/access-control";
import { TagBoolean, Tag } from "../shared/Tag";
import { ButtonBoolean, Button } from "../shared/Button";
import { Accordion } from "../shared/Accordion";
import {
  baseCxHook,
  cxDataHook,
  setCxMapiAccess,
  setCxIsRoot,
  setCxHieEnabledStatus,
  setCxOrganzation,
} from "../shared/state-management";
import FeatureFlagForm from "./feature-flags/FeatureFlagForm";
import OrganizationForm from "./organization/OrganizationInternalForm";
import { isProvider, isItVendor } from "../shared/util";
import { setHieOrganizationActive } from "./organization/hie-active";
import useMetriportToast from "../shared/toast";

export default function CustomerPage() {
  const { state, dispatch } = useAppContext();
  const { cxId } = useParams();
  const navigate = useNavigate();
  const toast = useMetriportToast();

  const [customer, setCustomer] = useState<CustomerMapEntry | undefined>(undefined);
  const [isRoot, setIsRoot] = useState<boolean | undefined>(undefined);
  const [cwStatus, setCwStatus] = useState<CxFeatureFlagDetails | undefined>(undefined);
  const [cqStatus, setCqStatus] = useState<CxFeatureFlagDetails | undefined>(undefined);
  const [epicStatus, setEpicStatus] = useState<CxFeatureFlagDetails | undefined>(undefined);
  const [demoAugStatus, setDemoAugStatus] = useState<CxFeatureFlagDetails | undefined>(undefined);
  const [hasMapiAccess, setHasMapiAccess] = useState<boolean | undefined>(undefined);
  const [organization, setOrganization] = useState<ExtendedOrganization | undefined>(undefined);
  const { isOpen: isOpenFF, onOpen: onOpenFF, onClose: onCloseFF } = useDisclosure();
  const {
    isOpen: isOpenInternal,
    onOpen: onOpenInternal,
    onClose: onClosenternal,
  } = useDisclosure();

  const [loadingBaseData, setLoadingBaseData] = useState<boolean>(false);
  const [loadingCxData, setLoadingCxData] = useState<boolean>(false);
  const [loadingHieOrgs, setLoadingHieOrgs] = useState<boolean>(false);

  const [onClickRunningCw, setOnClickRunningCw] = useState<boolean>(false);
  const [onClickRunningCq, setOnClickRunningCq] = useState<boolean>(false);

  const rootActionRequiredRoles = [roleAdmin];

  useEffect(() => {
    if (state.isLoaded && cxId) {
      setLoadingBaseData(true);
      baseCxHook({
        state,
        dispatch,
        cxId,
        setCustomer,
        setIsRoot,
        setCwStatus,
        setCqStatus,
        setEpicStatus,
        setDemoAugStatus,
        setHasMapiAccess,
        toast,
      });
      setLoadingCxData(true);
      setLoadingHieOrgs(true);
      cxDataHook({
        state,
        dispatch,
        cxId,
        setOrganization,
        toast,
      });
    }
  }, [state.isLoaded]);

  useEffect(() => {
    if (
      customer !== undefined &&
      isRoot !== undefined &&
      cwStatus !== undefined &&
      cqStatus !== undefined &&
      epicStatus !== undefined &&
      demoAugStatus !== undefined &&
      hasMapiAccess !== undefined
    ) {
      setLoadingBaseData(false);
    }
  }, [customer, isRoot, cwStatus, cwStatus, epicStatus, demoAugStatus, hasMapiAccess]);

  useEffect(() => {
    if (organization !== undefined) setLoadingCxData(false);
    if (
      organization &&
      organization.cqOrganization !== undefined &&
      organization.cwOrganization !== undefined
    ) {
      setLoadingHieOrgs(false);
    }
  }, [organization]);

  function openFFForm() {
    onOpenFF();
  }

  function closeFFForm() {
    onCloseFF();
  }

  function submitFFForm(cxId: string, newHieEnabledStatus: CxFeatureFlagStatus) {
    setCxHieEnabledStatus({
      cxId,
      state,
      dispatch,
      setCwStatus,
      setCqStatus,
      setEpicStatus,
      hieEnabledStatus: newHieEnabledStatus,
    });
    onCloseFF();
  }

  function openInternalForm() {
    onOpenInternal();
  }

  function closeInternalForm() {
    onClosenternal();
  }

  function submitInternalForm(cxId: string, newOrganization: Organization) {
    setCxOrganzation({
      cxId,
      state,
      dispatch,
      setter: setOrganization,
      organization: newOrganization,
      cqOrganization: organization?.cqOrganization,
      cwOrganization: organization?.cwOrganization,
    });
    onClosenternal();
  }

  async function handleIsRootChange(cxId: string, isRoot: boolean) {
    if (isRoot) {
      await unassignRoot(cxId);
    } else {
      await assignRoot(cxId);
    }
    setCxIsRoot({
      cxId,
      state,
      dispatch,
      setter: setIsRoot,
      isRoot: !isRoot,
    });
  }

  async function handleHasMapiAccessChange(cxId: string, hasMapiAccess: boolean) {
    if (hasMapiAccess) {
      await revokeMapiAccess(cxId);
    } else {
      await allowMapiAccess(cxId);
    }
    setCxMapiAccess({
      cxId,
      state,
      dispatch,
      setter: setHasMapiAccess,
      hasMapiAccess: !hasMapiAccess,
    });
  }

  return (
    <Box>
      <VStack p={10} align="start">
        {loadingBaseData ? (
          <Skeleton mt={5} h={100} w={"4xl"} />
        ) : (
          <>
            {customer !== undefined && (
              <Box>
                <Text h={10} pt={3} fontWeight={"bold"}>
                  Customer Info
                </Text>
                <Flex mb={2}>
                  <Tag id="cxId" label={`${customer.rootCustomer.id}`} canCopy={true} />
                  <Tag id="cxEmail" label={`${customer.rootCustomer.email}`} canCopy={true} />
                </Flex>
                <Box mt={50}>
                  <Text pt={3} fontWeight={"bold"}>
                    Customer Details
                  </Text>
                  {isRoot !== undefined && (
                    <Box mb={2}>
                      {areRolesIncluded(rootActionRequiredRoles, state.userRoles) ? (
                        <ButtonBoolean
                          id="isRoot"
                          check={isRoot}
                          disableOnClick={false}
                          onClick={async () =>
                            await handleIsRootChange(customer.rootCustomer.id, isRoot)
                          }
                          label="Root Status"
                          activeColor="green"
                        />
                      ) : (
                        <Flex>
                          <TagBoolean id="isRoot" check={isRoot} label={`Root Status`} />
                          <Text>Please contact an admin to update root status</Text>
                        </Flex>
                      )}
                    </Box>
                  )}
                  {isRoot !== undefined && isRoot ? (
                    <Box>
                      {cwStatus !== undefined &&
                        cqStatus !== undefined &&
                        epicStatus !== undefined &&
                        demoAugStatus !== undefined && (
                          <Box>
                            <Flex mb={2}>
                              <ButtonBoolean
                                id="cwStatus"
                                check={cwStatus.cxInFFValues}
                                disableOnClick={false}
                                onClick={openFFForm}
                                label={
                                  !cwStatus.ffEnabled
                                    ? `CW Enabled (CW NOT Globally Enabled)`
                                    : `CW Enabled`
                                }
                                activeColor="green"
                              />
                              <ButtonBoolean
                                id="cqStatus"
                                check={cqStatus.cxInFFValues}
                                disableOnClick={false}
                                onClick={openFFForm}
                                label={
                                  !cqStatus.ffEnabled
                                    ? `CQ Enabled (CQ NOT Globally Enabled)`
                                    : `CQ Enabled`
                                }
                                activeColor="green"
                              />
                              <ButtonBoolean
                                id="epicStatus"
                                check={epicStatus.cxInFFValues}
                                disableOnClick={false}
                                onClick={openFFForm}
                                label={
                                  !epicStatus.ffEnabled
                                    ? `Epic Enabled (Epic NOT Globally Enabled)`
                                    : `Epic Enabled`
                                }
                                activeColor="green"
                              />
                              <ButtonBoolean
                                id="demoAugStatus"
                                check={demoAugStatus.cxInFFValues}
                                disableOnClick={false}
                                onClick={openFFForm}
                                label={
                                  !demoAugStatus.ffEnabled
                                    ? `Demo Aug Enabled (Epic NOT Globally Enabled)`
                                    : `Demo Aug Enabled`
                                }
                                activeColor="green"
                              />
                            </Flex>
                            {(!cwStatus.ffEnabled ||
                              !cqStatus.ffEnabled ||
                              !epicStatus.ffEnabled ||
                              !demoAugStatus.ffEnabled) && (
                              <Alert status="error" mb={2} w={"max-content"} rounded="md">
                                <AlertTitle>
                                  At least one feature flag is not active globally - updating those
                                  feature flags will have no affect for the customer
                                </AlertTitle>
                              </Alert>
                            )}
                            <FeatureFlagForm
                              isOpen={isOpenFF}
                              onClose={closeFFForm}
                              onSubmit={submitFFForm}
                              cxId={customer.rootCustomer.id}
                              currentValues={{
                                cxsWithCWFeatureFlag: cwStatus,
                                cxsWithCQDirectFeatureFlag: cqStatus,
                                cxsWithEpicEnabled: epicStatus,
                                cxsWithDemoAugEnabled: demoAugStatus,
                              }}
                            />
                          </Box>
                        )}
                      {hasMapiAccess !== undefined && (
                        <Box>
                          <Flex mb={2}>
                            <ButtonBoolean
                              id="mapiAccess"
                              check={hasMapiAccess}
                              disableOnClick={false}
                              onClick={async () =>
                                await handleHasMapiAccessChange(
                                  customer.rootCustomer.id,
                                  hasMapiAccess
                                )
                              }
                              label="MAPI Access"
                              activeColor="green"
                            />
                          </Flex>
                        </Box>
                      )}
                      {loadingCxData ? (
                        <Skeleton mt={5} h={100} w={"full"} />
                      ) : (
                        <Box mt={50}>
                          {organization !== undefined && (
                            <Box>
                              {organization.organization && (
                                <Box>
                                  <Text pt={3} fontWeight={"bold"}>
                                    Organization Info
                                  </Text>
                                  <Flex mb={2}>
                                    <Tag
                                      id="orgId"
                                      label={`${organization.organization.id}`}
                                      canCopy={true}
                                    />
                                    <Tag
                                      id="orgType"
                                      label={`${organization.organization.businessType}`}
                                      icon="info"
                                    />
                                  </Flex>
                                </Box>
                              )}
                              <Flex mt={50} mb={5}>
                                <Text pt={3} fontWeight={"bold"}>
                                  Organization Details
                                </Text>
                                <Box ml={"auto"}>
                                  {!organization.organization && (
                                    <Button
                                      id="createOrg"
                                      onClick={openInternalForm}
                                      label="Create Organization"
                                      icon="add"
                                      iconSide="left"
                                    />
                                  )}
                                </Box>
                              </Flex>
                              {!organization.organization && <Text>No organization</Text>}
                              <Accordion
                                id="organization"
                                entries={
                                  organization.organization
                                    ? [[organization.organization.id, organization]]
                                    : ([] as [string, object][])
                                }
                                getLabel={(payload: object) => {
                                  const castPayload = payload as ExtendedOrganizationWithMapiOrg;
                                  return castPayload.organization.name;
                                }}
                                labelOnClick={() => {
                                  navigate(`/customer/${customer.rootCustomer.id}/facilities`);
                                }}
                                getLabelActions={
                                  isProvider(organization)
                                    ? (payload: object) => {
                                        const castPayload =
                                          payload as ExtendedOrganizationWithMapiOrg;
                                        return (
                                          <Flex>
                                            {castPayload.organization.cwApproved ? (
                                              <ButtonBoolean
                                                id="isCWActive"
                                                check={castPayload.organization.cwActive === true}
                                                disableOnClick={false}
                                                onClick={async () =>
                                                  await setHieOrganizationActive({
                                                    cxId: customer.rootCustomer.id,
                                                    state,
                                                    dispatch,
                                                    setOrganization,
                                                    hie: "commonwell",
                                                    organization: castPayload,
                                                    toast,
                                                    setRunning: setOnClickRunningCw,
                                                  })
                                                }
                                                label="CW Active"
                                                activeColor="green"
                                                icon={onClickRunningCw ? "spinner" : undefined}
                                                iconFalse={onClickRunningCw ? "spinner" : undefined}
                                              />
                                            ) : (
                                              <Tag id="cwApproved" label="CW Not Approved" />
                                            )}
                                            {castPayload.organization.cqApproved ? (
                                              <ButtonBoolean
                                                id="isCQActive"
                                                check={castPayload.organization.cqActive === true}
                                                disableOnClick={false}
                                                onClick={async () =>
                                                  await setHieOrganizationActive({
                                                    cxId: customer.rootCustomer.id,
                                                    state,
                                                    dispatch,
                                                    setOrganization,
                                                    hie: "carequality",
                                                    organization: castPayload,
                                                    toast,
                                                    setRunning: setOnClickRunningCq,
                                                  })
                                                }
                                                label="CQ Active"
                                                activeColor="green"
                                                icon={onClickRunningCq ? "spinner" : undefined}
                                                iconFalse={onClickRunningCq ? "spinner" : undefined}
                                                css={{ ml: 2 }}
                                              />
                                            ) : (
                                              <Tag id="cqApproved" label="CQ Not Approved" />
                                            )}
                                          </Flex>
                                        );
                                      }
                                    : () => {
                                        return (
                                          <Tag
                                            id="orgType"
                                            label={`No HIE Actions`}
                                            icon="info"
                                            color="orange"
                                          />
                                        );
                                      }
                                }
                                getPanelActions={() => {
                                  return (
                                    <Button
                                      id="updateOrg"
                                      onClick={openInternalForm}
                                      label="Update Organization"
                                      icon="edit"
                                      iconSide="left"
                                    />
                                  );
                                }}
                                getPanelInfo={() => {
                                  if (loadingHieOrgs) return <Skeleton mt={5} h={100} w={"full"} />;
                                }}
                                getPanelInfoDivider={!loadingHieOrgs}
                                getPanelInfoJsonExcludeKeys={
                                  isItVendor(organization)
                                    ? ["cqOrganization", "cwOrganization"]
                                    : []
                                }
                              />
                              {isOpenInternal && (
                                <OrganizationForm
                                  isOpen={isOpenInternal}
                                  onClose={closeInternalForm}
                                  onSubmit={submitInternalForm}
                                  cxId={customer.rootCustomer.id}
                                  currentOrg={organization.organization ?? undefined}
                                />
                              )}
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Text mb={5}>No further information available for non-Root customers.</Text>
                  )}
                </Box>
              </Box>
            )}
          </>
        )}
      </VStack>
    </Box>
  );
}
