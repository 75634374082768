import { ExtendedOrganization } from "../api/schemas/organization";

export enum OrganizationBizType {
  healthcareProvider = "healthcare_provider",
  healthcareITVendor = "healthcare_it_vendor",
}

export enum TreatmentType {
  acuteCare = "acuteCare",
  ambulatory = "ambulatory",
  hospital = "hospital",
  labSystems = "labSystems",
  pharmacy = "pharmacy",
  postAcuteCare = "postAcuteCare",
}

export type OrganizationMap = { [key: string]: ExtendedOrganization | undefined };

export type CqOrgRole = "Implementer" | "Connection";

export const CqMetriportDataDefault = {
  contactName: "Metriport Team",
  phone: "415-941-3282",
  email: "engineering+carequality@metriport.com",
  role: "Connection" as CqOrgRole,
  organizationBizType: OrganizationBizType.healthcareProvider,
};
