import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Stack, Box, Flex, VStack, Text, useDisclosure, Skeleton } from "@chakra-ui/react";
import { useCSVDownloader } from "react-papaparse";
import { ExtendedOrganization } from "../../../api/schemas/organization";
import { Facility, ExtendedFacility } from "../../../api/schemas/facility";
import { PatientCoverage } from "../../../api/schemas/patient";
import { CustomerMapEntry } from "../../../domain/customer";
import { useAppContext } from "../../contexts/app/index";
import { FacilitiesMap } from "../../../domain/facility";
import {
  baseCxHook,
  cxDataHook,
  facilityDataHook,
  facilityPatientDataHook,
  setCxFacilities,
} from "../../shared/state-management";
import { Accordion } from "../../shared/Accordion";
import { Tag } from "../../shared/Tag";
import { Input } from "../../shared/Input";
import { ButtonBoolean, Button } from "../../shared/Button";
import { Table } from "../../shared/Table";
import { filterFacilities, isItVendor, isProvider } from "../../shared/util";
import { setHieFacilityActive } from "./hie-active";
import { runCoverageAssessment } from "./coverage-assessment";
import useMetriportToast from "../../shared/toast";
import FacilityInternalForm from "./FacilityInternalForm";

const coverageAssessmentColumns = [
  "id",
  "firstName",
  "lastName",
  "state",
  "downloadStatus",
  "docCount",
  "convertStatus",
  "fhirCount",
  "fhirDetails",
  "mrSummaryUrl",
];

export default function FacilitiesPage() {
  const { state, dispatch } = useAppContext();
  const { cxId, facilityId } = useParams();
  const navigate = useNavigate();
  const toast = useMetriportToast();

  const [customer, setCustomer] = useState<CustomerMapEntry | undefined>(undefined);
  const [isRoot, setIsRoot] = useState<boolean | undefined>(undefined);
  const [organization, setOrganization] = useState<ExtendedOrganization | undefined>(undefined);
  const [facilities, setFacilities] = useState<FacilitiesMap | undefined>(undefined);
  const [facility, setFacility] = useState<ExtendedFacility | undefined>(undefined);
  const {
    isOpen: isOpenInternal,
    onOpen: onOpenInternal,
    onClose: onCloseInternal,
  } = useDisclosure();

  const [loadingBaseData, setLoadingBaseData] = useState<boolean>(false);
  const [loadingCxData, setLoadingCxData] = useState<boolean>(false);
  const [loadingHieFacilities, setLoadingHieFacilities] = useState<boolean>(false);
  const [loadingFacilityPatients, setLoadingFacilityPatients] = useState<boolean>(false);

  const [onClickRunningCw, setOnClickRunningCw] = useState<boolean>(false);
  const [onClickRunningCq, setOnClickRunningCq] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [zodErrors, setZodErrors] = useState<string | undefined>(undefined);
  const [confirmCoverageAssessment, setConfirmCoverageAssessment] = useState<boolean>(false);

  const fileReader = new FileReader();
  const { CSVDownloader } = useCSVDownloader();

  useEffect(() => {
    if (state.isLoaded && cxId) {
      setLoadingBaseData(true);
      baseCxHook({
        state,
        dispatch,
        cxId,
        setCustomer,
        setIsRoot,
        toast,
      });
      setLoadingCxData(true);
      cxDataHook({
        state,
        dispatch,
        cxId,
        setOrganization,
        setFacilities,
        toast,
        hideHieOrgDisplay: true,
      });
    }
  }, [state.isLoaded]);

  useEffect(() => {
    if (
      state.isLoaded &&
      cxId &&
      facilityId &&
      organization &&
      isItVendor(organization) &&
      facilities &&
      facilities[facilityId] !== undefined
    ) {
      setLoadingHieFacilities(true);
      facilityDataHook({
        state,
        dispatch,
        cxId,
        setFacilities,
        facilityId,
        toast,
      });
    }
  }, [state.isLoaded, organization, facilities]);

  useEffect(() => {
    if (customer !== undefined && isRoot !== undefined) setLoadingBaseData(false);
  }, [customer, isRoot]);

  useEffect(() => {
    if (organization !== undefined && facilities !== undefined) setLoadingCxData(false);
  }, [organization, facilities]);

  useEffect(() => {
    if (
      facilityId &&
      facilities &&
      facilities[facilityId] !== undefined &&
      facilities[facilityId]?.cqFacility !== undefined &&
      facilities[facilityId]?.cwFacility !== undefined
    ) {
      setLoadingHieFacilities(false);
    }
    if (
      facilityId &&
      facilities &&
      facilities[facilityId] !== undefined &&
      facilities[facilityId]?.patientsWithAssessments !== undefined
    ) {
      setLoadingFacilityPatients(false);
    }
  }, [facilityId, facilities]);

  function openInternalForm(facility: ExtendedFacility | undefined) {
    setFacility(facility);
    onOpenInternal();
  }

  function closeInternalForm() {
    onCloseInternal();
  }

  function submitInternalForm(
    cxId: string,
    currentFacility: Facility | undefined,
    newFacility: Facility
  ) {
    const existingFacilities = filterFacilities(facilities, currentFacility?.id);
    setCxFacilities({
      cxId,
      state,
      dispatch,
      setter: setFacilities,
      facilities: [
        ...existingFacilities,
        {
          facility: newFacility,
          cqFacility: facility?.cqFacility,
          cwFacility: facility?.cwFacility,
        },
      ],
    });
    onCloseInternal();
  }

  function handleOnCsvChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) setFile(e.target.files[0]);
  }

  async function handleCoverageAssessment(
    cxId: string,
    facilityId: string,
    validate: boolean
  ): Promise<boolean | void> {
    if (file) {
      fileReader.onload = async function (event) {
        const csvOutput = event.target?.result;
        if (csvOutput) {
          const { success, errorStr } = await runCoverageAssessment({
            cxId,
            input: csvOutput,
            facilityId,
            toast,
            validate,
          });
          if (success) {
            toast.success({
              title: `Coverage Assessment ${
                validate
                  ? "validated."
                  : "started. This process can take up to 20 minutes to complete."
              }`,
            });
            setZodErrors(undefined);
            if (validate) setConfirmCoverageAssessment(true);
          }
          if (errorStr) {
            setZodErrors(errorStr);
          }
        }
      };
      fileReader.readAsText(file);
    } else {
      toast.warning({ title: "No file selected" });
    }
  }

  function patientsToCsv(patients: PatientCoverage[], replaceUndefined: boolean) {
    return patients
      .map(p => {
        return { ...p, state: p.address[0].state };
      })
      .map(p => {
        return Object.fromEntries(
          coverageAssessmentColumns.map(col => {
            const value = p[col as keyof PatientCoverage] ?? (replaceUndefined ? "" : undefined);
            return [col, value];
          })
        );
      });
  }

  return (
    <Box>
      <VStack p={10} align="start">
        {loadingBaseData ? (
          <Skeleton mt={5} h={100} w={"4xl"} />
        ) : (
          <>
            {customer !== undefined && (
              <Box>
                <Text h={10} pt={3} fontWeight={"bold"}>
                  Customer Info
                </Text>
                <Flex mb={2}>
                  <Tag id="cxId" label={`${customer.rootCustomer.id}`} canCopy={true} />
                  <Tag id="cxEmail" label={`${customer.rootCustomer.email}`} canCopy={true} />
                </Flex>
                {isRoot !== undefined && isRoot ? (
                  <Box>
                    {loadingCxData ? (
                      <Skeleton mt={5} h={100} w={"full"} />
                    ) : (
                      <Box>
                        {organization !== undefined && facilities !== undefined && (
                          <Box>
                            {organization.organization ? (
                              <Box>
                                <Text pt={3} fontWeight={"bold"}>
                                  Organization Info
                                </Text>
                                <Flex mb={2}>
                                  <Tag
                                    id="orgId"
                                    label={`${organization.organization.id}`}
                                    canCopy={true}
                                  />
                                  <Tag
                                    id="orgType"
                                    label={`${organization.organization.businessType}`}
                                    icon="info"
                                  />
                                </Flex>
                                <Flex mt={50} mb={5}>
                                  <Text pt={3} fontWeight={"bold"}>
                                    Facility Details
                                  </Text>
                                  <Box ml={"auto"}>
                                    <Button
                                      id="createFacility"
                                      onClick={() => openInternalForm(undefined)}
                                      label="Create Facility"
                                      icon="add"
                                      iconSide="left"
                                    />
                                  </Box>
                                </Flex>
                                {Object.entries(facilities).length == 0 && (
                                  <Text>No facilities</Text>
                                )}
                                <Accordion
                                  id="facilities"
                                  defaultId={facilityId}
                                  entries={
                                    Object.entries(facilities)
                                      .filter(entry => entry[1] !== undefined)
                                      .sort((a, b) =>
                                        (a[1] as ExtendedFacility).facility.name >
                                        (b[1] as ExtendedFacility).facility.name
                                          ? -1
                                          : 1
                                      ) as [string, object][]
                                  }
                                  accordionButtonOnClick={(payload: object) => {
                                    const castPayload = payload as ExtendedFacility;
                                    if (
                                      state.isLoaded &&
                                      isItVendor(organization) &&
                                      (castPayload.cqFacility === undefined ||
                                        castPayload.cwFacility === undefined)
                                    ) {
                                      setLoadingHieFacilities(true);
                                      facilityDataHook({
                                        state,
                                        dispatch,
                                        cxId: customer.rootCustomer.id,
                                        setFacilities,
                                        facilityId: castPayload.facility.id,
                                        toast,
                                      });
                                    }
                                    navigate(
                                      `/customer/${cxId}/facilities/${castPayload.facility.id}`,
                                      {
                                        replace: true,
                                      }
                                    );
                                  }}
                                  accordionButtonOnClickExpandOnly={true}
                                  getLabel={(payload: object) => {
                                    const castPayload = payload as ExtendedFacility;
                                    return castPayload.facility.name;
                                  }}
                                  getLabelActions={
                                    isItVendor(organization)
                                      ? (payload: object) => {
                                          const castPayload = payload as ExtendedFacility;
                                          return (
                                            <Flex>
                                              {castPayload.facility.cwApproved ? (
                                                <ButtonBoolean
                                                  id="isCWActive"
                                                  check={castPayload.facility.cwActive === true}
                                                  disableOnClick={false}
                                                  onClick={async () =>
                                                    await setHieFacilityActive({
                                                      cxId: customer.rootCustomer.id,
                                                      state,
                                                      dispatch,
                                                      setFacilities,
                                                      hie: "commonwell",
                                                      facility: castPayload,
                                                      facilitiesMap: facilities,
                                                      toast,
                                                      setRunning: setOnClickRunningCw,
                                                    })
                                                  }
                                                  label="CW Active"
                                                  activeColor="green"
                                                  icon={onClickRunningCw ? "spinner" : undefined}
                                                  iconFalse={
                                                    onClickRunningCw ? "spinner" : undefined
                                                  }
                                                />
                                              ) : (
                                                <Tag id="cwApproved" label="CW Not Approved" />
                                              )}
                                              {castPayload.facility.cqApproved ? (
                                                <ButtonBoolean
                                                  id="isCQActive"
                                                  check={castPayload.facility.cqActive === true}
                                                  disableOnClick={false}
                                                  onClick={async () =>
                                                    await setHieFacilityActive({
                                                      cxId: customer.rootCustomer.id,
                                                      state,
                                                      dispatch,
                                                      setFacilities,
                                                      hie: "carequality",
                                                      facility: castPayload,
                                                      facilitiesMap: facilities,
                                                      toast,
                                                      setRunning: setOnClickRunningCq,
                                                    })
                                                  }
                                                  label="CQ Active"
                                                  activeColor="green"
                                                  icon={onClickRunningCq ? "spinner" : undefined}
                                                  iconFalse={
                                                    onClickRunningCq ? "spinner" : undefined
                                                  }
                                                />
                                              ) : (
                                                <Tag id="cqApproved" label="CQ Not Approved" />
                                              )}
                                            </Flex>
                                          );
                                        }
                                      : () => {
                                          return (
                                            <Tag
                                              id="orgType"
                                              label={`No HIE Actions`}
                                              icon="info"
                                              color="orange"
                                            />
                                          );
                                        }
                                  }
                                  getPanelActions={(payload: object) => {
                                    const castPayload = payload as ExtendedFacility;
                                    return (
                                      <Stack>
                                        <Button
                                          id="updateFacility"
                                          onClick={() => openInternalForm(castPayload)}
                                          label="Update Facility"
                                          icon="edit"
                                          iconSide="left"
                                        />
                                        <Text mt={5} align={"center"}>
                                          Coverage Assessment
                                        </Text>
                                        <Input
                                          id="coverageAssessmentInput"
                                          onChange={e => handleOnCsvChange(e)}
                                          css={{ type: "file", accept: "csv" }}
                                        />
                                        {confirmCoverageAssessment ? (
                                          <Button
                                            id="coverageAssessmentStart"
                                            onClick={async () => {
                                              await handleCoverageAssessment(
                                                customer.rootCustomer.id,
                                                castPayload.facility.id,
                                                false
                                              );
                                              setConfirmCoverageAssessment(false);
                                            }}
                                            label="Start Coverage Assessment"
                                            color="green"
                                          />
                                        ) : (
                                          <Button
                                            id="coverageAssessmentStartConfirm"
                                            onClick={async () => {
                                              await handleCoverageAssessment(
                                                customer.rootCustomer.id,
                                                castPayload.facility.id,
                                                true
                                              );
                                            }}
                                            label="Validate Coverage Assessment"
                                          />
                                        )}
                                        <Button
                                          id="getPatients"
                                          onClick={() => {
                                            if (state.isLoaded) {
                                              setLoadingFacilityPatients(true);
                                              facilityPatientDataHook({
                                                state,
                                                dispatch,
                                                cxId: customer.rootCustomer.id,
                                                setFacilities,
                                                facilityId: castPayload.facility.id,
                                                toast,
                                              });
                                            }
                                          }}
                                          label="Get Coverage Assessment"
                                        />
                                      </Stack>
                                    );
                                  }}
                                  getPanelInfo={(payload: object) => {
                                    if (loadingHieFacilities || loadingFacilityPatients) {
                                      return <Skeleton mt={5} h={100} w={"full"} />;
                                    }
                                    if (zodErrors) {
                                      return (
                                        <Stack mt={5}>
                                          <Flex justifyContent={"space-between"}>
                                            <Text pt={3}>Coverage Asessment Failed Validation</Text>
                                            <Button
                                              id="clearErrors"
                                              onClick={() => setZodErrors(undefined)}
                                              label="Clear errors"
                                              icon="delete"
                                              css={{ ml: 5 }}
                                            />
                                          </Flex>
                                          <Text mt={5} color="red">
                                            {zodErrors}
                                          </Text>
                                        </Stack>
                                      );
                                    }
                                    const castPayload = payload as ExtendedFacility;
                                    if (castPayload.patientsWithAssessments) {
                                      const numPatients =
                                        castPayload.patientsWithAssessments.length;
                                      return (
                                        <Stack mt={5}>
                                          <Flex>
                                            <Text>Coverage Assessment</Text>
                                            {numPatients > 0 && (
                                              <>
                                                <Box ml={5} color="blue">
                                                  <CSVDownloader
                                                    filename={`coverage_assessment_cxId${customer.rootCustomer.id}_facilityId${castPayload.facility.id}`}
                                                    data={patientsToCsv(
                                                      castPayload.patientsWithAssessments,
                                                      true
                                                    )}
                                                  >
                                                    Download
                                                  </CSVDownloader>
                                                </Box>
                                                <Text
                                                  ml={5}
                                                >{`Total Patients: ${numPatients}`}</Text>
                                                <Text ml={5}>{`Current Coverage: ${Math.ceil(
                                                  (100 *
                                                    castPayload.patientsWithAssessments.filter(
                                                      p => p.fhirCount > 0
                                                    ).length) /
                                                    numPatients
                                                )}%`}</Text>
                                                <Text ml={5}>{`Current Density: ${Math.ceil(
                                                  castPayload.patientsWithAssessments.reduce(
                                                    (a, b) => a + b.fhirCount,
                                                    0
                                                  ) / numPatients
                                                )} fhir resources / patient`}</Text>
                                              </>
                                            )}
                                          </Flex>
                                          {castPayload.patientsWithAssessments.length > 0 ? (
                                            <Table
                                              id="coverageAssessment"
                                              columns={coverageAssessmentColumns}
                                              data={patientsToCsv(
                                                castPayload.patientsWithAssessments,
                                                false
                                              )}
                                              linkColumns={["mrSummaryUrl"]}
                                            />
                                          ) : (
                                            <Text>No patients</Text>
                                          )}
                                        </Stack>
                                      );
                                    }
                                  }}
                                  getPanelInfoDivider={!loadingHieFacilities}
                                  getPanelInfoJsonExcludeKeys={
                                    isProvider(organization)
                                      ? [
                                          "patientsWithAssessments",
                                          "cqOrganization",
                                          "cwOrganization",
                                        ]
                                      : ["patientsWithAssessments"]
                                  }
                                />
                                {isOpenInternal && (
                                  <FacilityInternalForm
                                    isOpen={isOpenInternal}
                                    onClose={closeInternalForm}
                                    onSubmit={submitInternalForm}
                                    cxId={customer.rootCustomer.id}
                                    currentFacility={facility?.facility}
                                    isMapi={isProvider(organization)}
                                  />
                                )}
                              </Box>
                            ) : (
                              <Text mt={10} mb={5}>
                                No organization
                              </Text>
                            )}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Text mb={5}>No further information available for non-Root customers.</Text>
                )}
              </Box>
            )}
          </>
        )}
      </VStack>
    </Box>
  );
}
