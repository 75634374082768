import { AmplifyUser } from "@aws-amplify/ui";
import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from "react";
import { setAuthTokenHeaders } from "../../../api/api";
import { getCustomers } from "../../../api/customer";
import { createCustomerAndRootMaps } from "../../../domain/customer";
import { getRoles } from "../../../shared/access-control";
import { AppStateActionType } from "./reducer";
import useMetriportToast from "../../shared/toast";
import { fetchUserToken, captureAndDisplayError } from "../../shared/util";
import { AppState, AppStateAction, initialState, reducer } from "./reducer";

interface IAppStateContext {
  state: AppState;
  dispatch: Dispatch<AppStateAction>;
}

export const AppStateContext = createContext<IAppStateContext>({
  state: initialState,
  dispatch: () => null,
});

export const AppStateProvider = ({
  user,
  children,
}: {
  user: AmplifyUser | undefined;
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const toast = useMetriportToast();

  useEffect(() => {
    async function fetchInitialState() {
      try {
        const token = await fetchUserToken();
        setAuthTokenHeaders(token);

        if (!user) {
          throw new Error("Failed to fetch user");
        }

        const userRoles = getRoles(token);
        let initialCustomersState = {};
        if (userRoles.length > 0) {
          const customers = await getCustomers();
          initialCustomersState = createCustomerAndRootMaps(customers);
          initialCustomersState = {
            ...initialCustomersState,
            rawCustomers: customers,
          };
        }

        dispatch({
          type: AppStateActionType.update,
          newState: {
            authToken: token,
            user,
            userRoles,
            isLoaded: true,
            ...initialCustomersState,
          },
        });
      } catch (error) {
        captureAndDisplayError({
          error,
          msg: "Failed to initialize the application",
          context: "fetchInitialState",
          captureMsg: "Component: AppStateProvider - initializing ops dash state failed",
          toast,
        });
      }
    }
    fetchInitialState();
  }, []);

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>{children}</AppStateContext.Provider>
  );
};

export const useAppContext = () => useContext(AppStateContext);
