import {
  Tag as BaseTag,
  TagLabel,
  TagRightIcon,
  TagLeftIcon,
  useClipboard,
} from "@chakra-ui/react";
import {
  CheckIcon,
  WarningIcon,
  EmailIcon,
  InfoOutlineIcon,
  EditIcon,
  DeleteIcon,
  AddIcon,
  ArrowRightIcon,
  CopyIcon,
} from "@chakra-ui/icons";
import useMetriportToast from "../shared/toast";

const tagMap = {
  check: CheckIcon,
  warning: WarningIcon,
  email: EmailIcon,
  info: InfoOutlineIcon,
  add: AddIcon,
  edit: EditIcon,
  delete: DeleteIcon,
  arrowRight: ArrowRightIcon,
};

export const Tag = ({
  id,
  label,
  icon,
  iconSide = "right",
  color = "blue",
  canCopy,
  css = {},
}: {
  id: string;
  label: string | React.ReactNode;
  icon?: keyof typeof tagMap;
  iconSide?: "left" | "right";
  color?: string;
  canCopy?: boolean;
  css?: object;
}) => {
  const toast = useMetriportToast();
  const { onCopy, value, setValue, hasCopied } = useClipboard("");
  const labelIsString = typeof label === "string";
  if (canCopy && labelIsString && value !== label) setValue(label);
  if (canCopy && hasCopied) toast.success({ title: "Copied." });
  return (
    <BaseTag h={10} p={2} m={1} key={id} variant="outline" colorScheme={color} {...css}>
      {icon && iconSide == "left" && <TagLeftIcon as={tagMap[icon]} />}
      {labelIsString ? <TagLabel w="max">{label}</TagLabel> : label}
      {icon && iconSide == "right" && <TagRightIcon as={tagMap[icon]} />}
      {canCopy && labelIsString && <CopyIcon ml={2} onClick={onCopy} />}
    </BaseTag>
  );
};

export const TagBoolean = ({
  id,
  label,
  check,
  icon,
  iconSide = "right",
  activeColor = "green",
  nonActiveColor = "red",
  css = {},
}: {
  id: string;
  label: string | React.ReactNode;
  check: boolean;
  icon?: keyof typeof tagMap;
  iconSide?: "left" | "right";
  activeColor?: string;
  nonActiveColor?: string;
  css?: object;
}) => {
  const color = check ? activeColor : nonActiveColor;
  const usedIcon = check ? (icon ? tagMap[icon] : CheckIcon) : WarningIcon;
  return (
    <BaseTag h={10} p={2} m={1} key={id} variant="outline" colorScheme={color} {...css}>
      {iconSide == "left" && <TagLeftIcon as={usedIcon} />}
      {typeof label === "string" ? <TagLabel w="max">{label}</TagLabel> : label}
      {iconSide == "right" && <TagRightIcon as={usedIcon} />}
    </BaseTag>
  );
};
