import { CognitoUser } from "@aws-amplify/auth";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { ExtendedOrganization } from "../../api/schemas/organization";
import { ExtendedFacility } from "../../api/schemas/facility";
import { FacilitiesMap } from "../../domain/facility";
import { CxFeatureFlagDetails } from "../../domain/app-config";
import { capture } from "../../shared/capture";
import { Toast } from "./toast";

export const fetchUserToken = async function () {
  const user: CognitoUser = await Auth.currentAuthenticatedUser();
  try {
    const token = await new Promise<string>((res, rej) => {
      user.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err) {
          rej(err);
          return;
        }
        if (session) {
          res(session.getIdToken().getJwtToken());
          return;
        }
        res("");
      });
    });
    return token;
  } catch (error) {
    const msg = `Error fetching user token`;
    capture.message(msg, {
      extra: { context: `userToken.fetch`, userId: user.getUsername(), error },
      level: "error",
    });
    return "";
  }
};

export function mapToOptions(map: { [k: string]: string }): { label: string; value: string }[] {
  return Object.keys(map).map(k => {
    return { label: map[k], value: k };
  });
}

export function captureAndDisplayError({
  error,
  msg,
  context,
  captureMsg,
  extras,
  toast,
  hideDisplay = false,
}: {
  error: unknown;
  msg: string;
  context: string;
  captureMsg: string;
  extras?: { [key: string]: unknown };
  toast: Toast;
  hideDisplay?: boolean;
}): void {
  if (!hideDisplay) {
    toast.error({ title: msg });
  }
  capture.error(captureMsg, {
    extra: {
      error,
      msg,
      context,
      ...extras,
    },
  });
}

export function checkFFActive(featureFlag: CxFeatureFlagDetails | undefined): boolean {
  return !!featureFlag && !!featureFlag.ffEnabled && !!featureFlag.cxInFFValues;
}

export function filterFacilities(
  facilitiesMap: FacilitiesMap | undefined,
  facilityId: string | undefined
): ExtendedFacility[] {
  return Object.values(facilitiesMap ?? {}).filter(
    f => f !== undefined && facilityId && f.facility.id !== facilityId
  ) as ExtendedFacility[];
}

export function isItVendor(organization?: ExtendedOrganization): boolean {
  return organization?.organization?.businessType === "healthcare_it_vendor";
}

export function isProvider(organization?: ExtendedOrganization): boolean {
  return organization?.organization?.businessType === "healthcare_provider";
}
