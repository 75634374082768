import { ButtonProps, Button as ChakraButton, ChakraProps } from "@chakra-ui/react";
import React, { forwardRef } from "react";

export const METRIPORT_PRIMARY = "#748df0";
export const Button = forwardRef<
  HTMLButtonElement,
  {
    type?: "button" | "reset" | "submit";
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    isLoading?: boolean;
    loadingText?: string;
    disabled?: boolean;
    children?: React.ReactNode;
  } & ChakraProps &
    ButtonProps
>(({ type, onClick, isLoading, loadingText, disabled, children, ...rest }, ref) => {
  return (
    <ChakraButton
      ref={ref}
      {...(rest.colorScheme
        ? undefined
        : {
            bg: METRIPORT_PRIMARY,
            color: "white",
            _hover: {
              bg: "#879ced",
            },
          })}
      onClick={onClick}
      isLoading={isLoading ?? false}
      loadingText={loadingText}
      isDisabled={disabled ?? false}
      type={type}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
});
