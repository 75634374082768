import { Stack, Code, Box, Flex, Text } from "@chakra-ui/react";

export const JsonPayload = ({
  payload,
  keysToExclude,
}: {
  payload: object;
  keysToExclude?: string[];
}) => {
  return (
    <Stack mb={2} w={"100%"} direction="column" p={5}>
      {Object.entries(payload).map(([key, value]) => {
        if (keysToExclude && keysToExclude.includes(key)) return;
        return (
          <Code>
            <Flex>
              <Text>{`${key}:`}</Text>
              <Box ml={2}>
                {typeof value !== "object" || !value
                  ? `${value}`
                  : JsonPayload({ payload: value, keysToExclude })}
              </Box>
            </Flex>
          </Code>
        );
      })}
    </Stack>
  );
};
