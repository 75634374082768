import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator, Theme, ThemeProvider, View, useTheme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { ChakraProvider, theme, Text, Image } from "@chakra-ui/react";
import { AppStateProvider } from "./components/contexts/app";
import * as Sentry from "@sentry/react";
import Home from "./components/home/HomePage";
import CustomerPage from "./components/customer/CustomerPage";
import FacilitiesPage from "./components/customer/facility/FacilitiesPage";
import Navbar from "./components/navbar/Navbar";
import "./index.css";

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
});

const logo = "logo.png";

const amplifyTheme: Theme = {
  name: "custom-theme",
  tokens: {
    colors: {
      font: {
        primary: { value: "black" },
        secondary: { value: "#879ced" },
      },
      brand: {
        primary: { "80": { value: "#748df0" } },
      },
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image marginTop={"40%"} alt="Metriport logo" width={"70%"} src={logo} />
        <Text>Operations Portal</Text>
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text>&copy; All Rights Reserved</Text>
      </View>
    );
  },
};

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-react-router-64-data-api
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App = () => {
  return (
    <ThemeProvider theme={amplifyTheme}>
      <Authenticator components={components} loginMechanisms={["email"]} hideSignUp>
        {({ signOut, user }) => (
          <main>
            <BrowserRouter>
              <ChakraProvider theme={theme}>
                <AppStateProvider user={user}>
                  <Navbar
                    children={
                      <SentryRoutes>
                        <Route path="/" element={<Home />} />
                        <Route path="/customer/:cxId" element={<CustomerPage />} />
                        <Route path="/customer/:cxId/facilities" element={<FacilitiesPage />} />
                        <Route
                          path="/customer/:cxId/facilities/:facilityId"
                          element={<FacilitiesPage />}
                        />
                      </SentryRoutes>
                    }
                    signOut={signOut}
                    user={user}
                  />
                </AppStateProvider>
              </ChakraProvider>
            </BrowserRouter>
          </main>
        )}
      </Authenticator>
    </ThemeProvider>
  );
};
