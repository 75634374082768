import { ExtendedFacility } from "../api/schemas/facility";

export enum FacilityType {
  initiatorAndResponder = "initiator_and_responder",
  initiatorOnly = "initiator_only",
}

export const defaultMapiValues = {
  cqActive: false,
  cqType: FacilityType.initiatorAndResponder,
  cqOboOid: null,
  cwActive: false,
  cwType: FacilityType.initiatorAndResponder,
  cwOboOid: null,
};

export type FacilitiesMap = { [key: string]: ExtendedFacility | undefined };
export type FacilityMap = { [key: string]: FacilitiesMap };
