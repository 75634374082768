import { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { get } from "lodash";
import { Text } from "@chakra-ui/react";
import { updateHieEnabledStatus } from "../../../api/internal";
import { booleanOrUndefinedSchema } from "../../../api/schemas/shared";
import { CxFeatureFlagStatus } from "../../../domain/app-config";
import Constants from "../../shared/constants";
import DrawerForm from "../../shared/form/Drawer";
import { Select } from "../../shared/form/Select";
import useMetriportToast from "../../shared/toast";
import { captureAndDisplayError } from "../../shared/util";

const formSchema = z.object({
  cwEnabled: booleanOrUndefinedSchema,
  cqEnabled: booleanOrUndefinedSchema,
  epicEnabled: booleanOrUndefinedSchema,
  demoAugEnabled: booleanOrUndefinedSchema,
});
type FormData = z.infer<typeof formSchema>;

export default function FeatureFlagForm({
  isOpen,
  onClose,
  onSubmit,
  cxId,
  currentValues,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (cxId: string, newHieEnabledStatus: CxFeatureFlagStatus) => void;
  cxId: string;
  currentValues: CxFeatureFlagStatus;
}) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const toast = useMetriportToast();

  const update = async (data: FormData) => {
    try {
      setIsSubmitting(true);
      const cwEnabled = data.cwEnabled !== "undefined" ? data.cwEnabled === "true" : undefined;
      const cqEnabled = data.cqEnabled !== "undefined" ? data.cqEnabled === "true" : undefined;
      const epicEnabled =
        data.epicEnabled !== "undefined" ? data.epicEnabled === "true" : undefined;
      const demoAugEnabled =
        data.demoAugEnabled !== "undefined" ? data.demoAugEnabled === "true" : undefined;
      if (cwEnabled === undefined && cqEnabled === undefined && epicEnabled === undefined) {
        toast.warning({ title: "Must set at least one flag." });
        return;
      }
      await updateHieEnabledStatus({
        cxId,
        flags: {
          cwEnabled,
          cqEnabled,
          epicEnabled,
          demoAugEnabled,
        },
      });
      toast.success({ title: "Feature flags saved." });
      onSubmit(cxId, {
        cxsWithCWFeatureFlag: {
          cxInFFValues: cwEnabled ?? currentValues.cxsWithCWFeatureFlag.cxInFFValues,
          ffEnabled: currentValues.cxsWithCWFeatureFlag.ffEnabled,
        },
        cxsWithCQDirectFeatureFlag: {
          cxInFFValues: cqEnabled ?? currentValues.cxsWithCQDirectFeatureFlag.cxInFFValues,
          ffEnabled: currentValues.cxsWithCQDirectFeatureFlag.ffEnabled,
        },
        cxsWithEpicEnabled: {
          cxInFFValues: epicEnabled ?? currentValues.cxsWithEpicEnabled.cxInFFValues,
          ffEnabled: currentValues.cxsWithEpicEnabled.ffEnabled,
        },
        cxsWithDemoAugEnabled: {
          cxInFFValues: epicEnabled ?? currentValues.cxsWithDemoAugEnabled.cxInFFValues,
          ffEnabled: currentValues.cxsWithDemoAugEnabled.ffEnabled,
        },
      });
    } catch (error) {
      captureAndDisplayError({
        error,
        msg: "Failed to updated feature flags",
        context: "update",
        captureMsg: `Component: FeatureFlagForm - update failed`,
        toast,
        extras: {
          cxId,
        },
      });
    }
    setIsSubmitting(false);
  };

  return (
    <DrawerForm<FormData>
      title={"Update Feature Flags"}
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onSubmit={update}
      onClose={onClose}
      resolver={zodResolver(formSchema)}
      defaultValues={{
        cwEnabled: `${currentValues.cxsWithCWFeatureFlag.cxInFFValues}`,
        cqEnabled: `${currentValues.cxsWithCQDirectFeatureFlag.cxInFFValues}`,
        epicEnabled: `${currentValues.cxsWithEpicEnabled.cxInFFValues}`,
        demoAugEnabled: `${currentValues.cxsWithDemoAugEnabled.cxInFFValues}`,
      }}
      btSaveId="org-save"
      btCloseId="org-close"
    >
      {({ register, formState: { errors } }) => (
        <>
          <Select
            {...register("cwEnabled")}
            label="CW Enabled"
            options={Constants.booleanAndUndefinedOptions}
            error={get(errors, "cwEnabled")}
          />
          {!currentValues.cxsWithCWFeatureFlag.ffEnabled && (
            <Text mt={2} pl={2} color={"red"}>
              This feature flag is not enabled globally - updating it will have no affect for the
              customer
            </Text>
          )}
          <Select
            {...register("cqEnabled")}
            label="CQ Enabled"
            options={Constants.booleanAndUndefinedOptions}
            error={get(errors, "cqEnabled")}
          />
          {!currentValues.cxsWithCQDirectFeatureFlag.ffEnabled && (
            <Text mt={2} pl={2} color={"red"}>
              This feature flag is not enabled globally - updating it will have no affect for the
              customer
            </Text>
          )}
          <Select
            {...register("epicEnabled")}
            label="Epic Enabled"
            options={Constants.booleanAndUndefinedOptions}
            error={get(errors, "epicEnabled")}
          />
          {!currentValues.cxsWithEpicEnabled.ffEnabled && (
            <Text mt={2} pl={2} color={"red"}>
              This feature flag is not enabled globally - updating it will have no affect for the
              customer
            </Text>
          )}
          <Select
            {...register("demoAugEnabled")}
            label="Demo Augmentation Enabled"
            options={Constants.booleanAndUndefinedOptions}
            error={get(errors, "demoAugEnabled")}
          />
          {!currentValues.cxsWithDemoAugEnabled.ffEnabled && (
            <Text mt={2} pl={2} color={"red"}>
              This feature flag is not enabled globally - updating it will have no affect for the
              customer
            </Text>
          )}
        </>
      )}
    </DrawerForm>
  );
}
