import { ExtendedFacility, Facility } from "../../../api/schemas/facility";
import { updateCqFacility, updateCwFacility } from "../../../api/internal";
import { FacilitiesMap } from "../../../domain/facility";
import { AppState, AppStateAction } from "../../contexts/app/reducer";
import { setCxFacilities } from "../../shared/state-management";
import { captureAndDisplayError, filterFacilities } from "../../shared/util";
import { Toast } from "../../shared/toast";
import { SetStateAction } from "react";

export async function setHieFacilityActive({
  cxId,
  state,
  dispatch,
  setFacilities,
  hie,
  facility,
  facilitiesMap,
  toast,
  setRunning,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  setFacilities: React.Dispatch<SetStateAction<FacilitiesMap | undefined>>;
  hie: "commonwell" | "carequality";
  facility: ExtendedFacility;
  facilitiesMap: FacilitiesMap;
  toast: Toast;
  setRunning: React.Dispatch<SetStateAction<boolean>>;
}) {
  setRunning(true);
  let activeFieldName: keyof Facility;
  let facilityFieldName: keyof ExtendedFacility;
  if (hie === "carequality") {
    activeFieldName = "cqActive";
    facilityFieldName = "cqFacility";
  } else {
    activeFieldName = "cwActive";
    facilityFieldName = "cwFacility";
  }
  const newActive = !facility.facility[activeFieldName];
  try {
    const updateFunc = hie === "carequality" ? updateCqFacility : updateCwFacility;
    await updateFunc(cxId, facility.facility.id, facility.facility.oid, { active: newActive });
    toast.success({ title: "Facility saved." });
    const existingFacilities = filterFacilities(facilitiesMap, facility.facility.id);
    setCxFacilities({
      cxId,
      state,
      dispatch,
      setter: setFacilities,
      facilities: [
        ...existingFacilities,
        {
          ...facility,
          [facilityFieldName]: {
            ...facility[facilityFieldName],
            active: newActive,
          },
          facility: {
            ...facility.facility,
            [activeFieldName]: newActive,
          },
        },
      ],
    });
  } catch (error) {
    // TODO Check for timeout and flag as warning (#1940)
    captureAndDisplayError({
      error,
      msg: "Failed to update facility",
      context: "setHieFacilityActive",
      captureMsg: `updateFunc failed`,
      toast,
      extras: {
        cxId,
        hie,
        facilityId: facility.facility.id,
        hieOrgId: facility.facility.oid,
      },
    });
  }
  setRunning(false);
}
