import { apiOssProxyInternal } from "./api";
import {
  Facility,
  facilitySchema,
  FacilityInternalCreateOrUpdate,
  CwFacilityUpdate,
  CqFacilityUpdate,
} from "./schemas/facility";
import {
  Organization,
  organizationSchema,
  OrganizationInternalCreateOrUpdate,
  CqOrganizationUpdate,
  CwOrganizationUpdate,
  CqDirectoryEntryData,
  cqDirectoryEntryDataSchema,
  CwOrgData,
  cwOrgDataSchema,
} from "./schemas/organization";
import {
  CoverageAssessment,
  patientCoverageResponseSchema,
  PatientCoverageResponse,
} from "./schemas/patient";
import { CxFeatureFlagStatus } from "../domain/app-config";

export async function getCxData(
  cxId: string
): Promise<{ org: Organization; facilities: Facility[] }> {
  const resp = await apiOssProxyInternal.get(`/${cxId}/cx-data`);
  if (!resp.data) throw new Error(`Cx data not returned`);
  return {
    org: organizationSchema.parse(resp.data["org"]),
    facilities: resp.data["facilities"].map(facilitySchema.parse),
  };
}

export async function allowMapiAccess(cxId: string): Promise<void> {
  await apiOssProxyInternal.post(`/${cxId}/mapi-access`);
}

export async function revokeMapiAccess(cxId: string): Promise<void> {
  await apiOssProxyInternal.delete(`/${cxId}/mapi-access`);
}

export async function checkMapiAccess(cxId: string): Promise<{ hasMapiAccess: boolean }> {
  const resp = await apiOssProxyInternal.get(`/${cxId}/mapi-access`);
  if (!resp.data) throw new Error(`Customer not returned`);
  return resp.data;
}

export async function getCwFacility(cxId: string, id: string, oid: string): Promise<CwOrgData> {
  const resp = await apiOssProxyInternal.get(
    `/${cxId}/commonwell/ops/organization/${oid}?facilityId=${id}`
  );
  if (!resp.data) throw new Error(`CW Organization not returned`);
  return cwOrgDataSchema.parse(resp.data);
}

export async function getCqFacility(
  cxId: string,
  id: string,
  oid: string
): Promise<CqDirectoryEntryData> {
  const resp = await apiOssProxyInternal.get(
    `/${cxId}/carequality/ops/directory/organization/${oid}?facilityId=${id}`
  );
  if (!resp.data) throw new Error(`CQ Organization not returned`);
  return cqDirectoryEntryDataSchema.parse(resp.data);
}

export async function createOrUpdateFacility(
  cxId: string,
  facilityParams: FacilityInternalCreateOrUpdate
): Promise<Facility> {
  const resp = await apiOssProxyInternal.put(`/${cxId}/facility`, facilityParams);
  if (!resp.data) throw new Error(`Facility not returned`);
  return facilitySchema.parse(resp.data);
}

export async function updateCwFacility(
  cxId: string,
  id: string,
  oid: string,
  facilityParams: CwFacilityUpdate
): Promise<void> {
  await apiOssProxyInternal.put(
    `/${cxId}/commonwell/ops/facility/${oid}?facilityId=${id}`,
    facilityParams
  );
}

export async function updateCqFacility(
  cxId: string,
  id: string,
  oid: string,
  facilityParams: CqFacilityUpdate
): Promise<void> {
  await apiOssProxyInternal.put(
    `/${cxId}/carequality/ops/directory/facility/${oid}?facilityId=${id}`,
    facilityParams
  );
}

export async function getCwOrganization(cxId: string, oid: string): Promise<CwOrgData> {
  const resp = await apiOssProxyInternal.get(`/${cxId}/commonwell/ops/organization/${oid}`);
  if (!resp.data) throw new Error(`CW Organization not returned`);
  return cwOrgDataSchema.parse(resp.data);
}

export async function getCqOrganization(cxId: string, oid: string): Promise<CqDirectoryEntryData> {
  const resp = await apiOssProxyInternal.get(
    `/${cxId}/carequality/ops/directory/organization/${oid}`
  );
  if (!resp.data) throw new Error(`CQ Organization not returned`);
  return cqDirectoryEntryDataSchema.parse(resp.data);
}

export async function createOrUpdateOrganization(
  cxId: string,
  orgParams: OrganizationInternalCreateOrUpdate
): Promise<Organization> {
  const resp = await apiOssProxyInternal.put(`/${cxId}/organization`, orgParams);
  if (!resp.data) throw new Error(`Organization not returned`);
  return organizationSchema.parse(resp.data);
}

export async function updateCwOrganization(
  cxId: string,
  oid: string,
  orgParams: CqOrganizationUpdate
): Promise<void> {
  await apiOssProxyInternal.put(`/${cxId}/commonwell/ops/organization/${oid}`, orgParams);
}

export async function updateCqOrganization(
  cxId: string,
  oid: string,
  orgParams: CwOrganizationUpdate
): Promise<void> {
  await apiOssProxyInternal.put(
    `/${cxId}/carequality/ops/directory/organization/${oid}`,
    orgParams
  );
}

export async function getHieEnabledStatus(cxId: string): Promise<CxFeatureFlagStatus> {
  const resp = await apiOssProxyInternal.get(`/${cxId}/cx-ff-status`);
  if (!resp.data) throw new Error(`Feature Flag Status not returned`);
  return resp.data;
}

export async function updateHieEnabledStatus({
  cxId,
  flags,
}: {
  cxId: string;
  flags: {
    cwEnabled?: boolean;
    cqEnabled?: boolean;
    epicEnabled?: boolean;
    demoAugEnabled?: boolean;
  };
}): Promise<CxFeatureFlagStatus> {
  const params = new URLSearchParams();
  if (flags.cwEnabled !== undefined) params.set("cwEnabled", `${flags.cwEnabled}`);
  if (flags.cqEnabled !== undefined) params.set("cqEnabled", `${flags.cqEnabled}`);
  if (flags.epicEnabled !== undefined) params.set("epicEnabled", `${flags.epicEnabled}`);
  if (flags.demoAugEnabled !== undefined) params.set("demoAugEnabled", `${flags.demoAugEnabled}`);
  const resp = await apiOssProxyInternal.put(`/${cxId}/cx-ff-status?${params.toString()}`);
  if (!resp.data) throw new Error(`Customer not returned`);
  return resp.data;
}

export async function createCoverageAssessment({
  cxId,
  coverageAssessment,
  facilityId,
  validate = false,
}: {
  cxId: string;
  coverageAssessment: CoverageAssessment;
  facilityId: string;
  validate?: boolean;
}): Promise<void> {
  await apiOssProxyInternal.post(
    `/${cxId}/patient/bulk/coverage-assessment?facilityId=${facilityId}&dryrun=${validate}`,
    coverageAssessment
  );
}

export async function getCoverageAssessment({
  cxId,
  facilityId,
}: {
  cxId: string;
  facilityId: string;
}): Promise<PatientCoverageResponse> {
  const resp = await apiOssProxyInternal.get(
    `/${cxId}/patient/bulk/coverage-assessment?facilityId=${facilityId}`
  );
  if (!resp.data) throw new Error(`Coverage assessment patient data not returned`);
  return patientCoverageResponseSchema.parse(resp.data);
}
