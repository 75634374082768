import {
  Box,
  Flex,
  Accordion as BaseAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Divider,
} from "@chakra-ui/react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import { JsonPayload } from "./JsonPayload";
import { Tag } from "./Tag";

export const Accordion = ({
  id,
  entries,
  accordionButtonOnClick,
  accordionButtonOnClickExpandOnly = false,
  getLabel,
  labelOnClick,
  getLabelActions,
  getPanelInfo,
  getPanelInfoDivider = true,
  getPanelInfoJsonExcludeKeys,
  getPanelActions,
  defaultId,
}: {
  id: string;
  entries: [string, object][];
  accordionButtonOnClick?: (payload: object) => void;
  accordionButtonOnClickExpandOnly?: boolean;
  getLabel: (payload: object) => string | React.ReactNode;
  labelOnClick?: (payload: object) => void;
  getLabelActions?: (payload: object) => React.ReactNode;
  getPanelInfo?: (payload: object) => React.ReactNode;
  getPanelInfoDivider?: boolean;
  getPanelInfoJsonExcludeKeys?: string[];
  getPanelActions?: (payload: object) => React.ReactNode;
  defaultId?: string;
}) => {
  const defaultIndex = defaultId
    ? { defaultIndex: entries.map(entry => entry[0]).indexOf(defaultId) }
    : undefined;
  return (
    <BaseAccordion allowToggle {...defaultIndex} w="container.lg" key={id}>
      {entries.map(([id, payload]) => {
        return (
          <AccordionItem key={id} border={1} my={2}>
            {({ isExpanded }) => (
              <>
                <h2>
                  <Flex justifyContent={"space-between"}>
                    <AccordionButton
                      onClick={
                        (!accordionButtonOnClickExpandOnly && accordionButtonOnClick) ||
                        (accordionButtonOnClickExpandOnly && accordionButtonOnClick && !isExpanded)
                          ? () => accordionButtonOnClick(payload)
                          : undefined
                      }
                    >
                      <Flex justifyContent={"space-between"}>
                        <Box onClick={labelOnClick ? () => labelOnClick(payload) : undefined}>
                          <Tag
                            id="tagLabel"
                            label={getLabel(payload)}
                            css={{
                              w: "container.sm",
                              variant: labelOnClick ? "solid" : "outline",
                              _hover: labelOnClick ? { opacity: 0.75 } : undefined,
                            }}
                          />
                        </Box>
                        <Box ml={5} pt={3} h={10}>
                          {" "}
                          {isExpanded ? <MinusIcon /> : <AddIcon />}
                        </Box>
                      </Flex>
                    </AccordionButton>
                    <Flex h={10} pt={2}>
                      {getLabelActions ? getLabelActions(payload) : undefined}
                    </Flex>
                  </Flex>
                </h2>
                <AccordionPanel pl={2} pr={0}>
                  <Box>
                    <Flex justifyContent={"space-between"}>
                      <JsonPayload
                        payload={payload}
                        keysToExclude={["eTag", ...(getPanelInfoJsonExcludeKeys ?? [])]}
                      />
                      <Box textAlign={"right"}>
                        {getPanelActions ? getPanelActions(payload) : undefined}
                      </Box>
                    </Flex>
                    {getPanelInfo && getPanelInfo(payload) && (
                      <>
                        {getPanelInfoDivider && <Divider ml={5} color="black" border={"2px"} />}
                        <Box ml={5}>{getPanelInfo(payload)}</Box>
                      </>
                    )}
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        );
      })}
    </BaseAccordion>
  );
};
