import {
  CoverageAssessmentHeaders,
  compareHeaders,
  normalizeHeaders,
} from "../../../domain/patient";
import { createCoverageAssessment } from "../../../api/internal";
import { captureAndDisplayError } from "../../shared/util";
import { Toast } from "../../shared/toast";

export async function runCoverageAssessment({
  cxId,
  input,
  facilityId,
  validate = false,
  toast,
}: {
  cxId: string;
  input: string | ArrayBuffer;
  facilityId: string;
  validate?: boolean;
  toast: Toast;
}): Promise<{ success: boolean; errorStr?: string }> {
  const rows = input.toString().split("\n");
  const headers = normalizeHeaders(rows[0].split(","));
  if (!compareHeaders(CoverageAssessmentHeaders, headers)) {
    toast.error({
      title: `Headers must match the following in order: ${CoverageAssessmentHeaders.join(", ")}`,
    });
    return { success: false };
  }
  const patients = rows.slice(1).map((row: string) => {
    const patient: { [key: string]: string | undefined } = {};
    const patienValues = row.split(",");
    headers.forEach((header, i) => {
      const value = patienValues[i].trim();
      patient[header] = value === "" ? undefined : value;
    });
    return patient;
  });
  const coverageAssessment = { patients };
  try {
    await createCoverageAssessment({
      cxId,
      coverageAssessment,
      facilityId,
      validate,
    });
    return { success: true };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (
      error.response.status === 400 &&
      error.response.data.title === "Missing or invalid parameters"
    ) {
      let errorStr = error.response.data.detail as string;
      coverageAssessment.patients.map((patient, i) => {
        errorStr = errorStr.replaceAll(
          `patients,${i},`,
          `${patient.firstname} ${patient.lastname}, `
        );
      });
      toast.error({ title: "Errors validating the payload" });
      return { success: false, errorStr };
    } else {
      captureAndDisplayError({
        error,
        msg: "Failed to validate coverage assessment patients",
        context: "validatePatients",
        captureMsg: `createCoverageAssments (dryrun) failed`,
        toast,
        extras: {
          cxId,
          facilityId,
          numPatients: rows.length - 1,
        },
      });
      return { success: false };
    }
  }
}
