import axios from "axios";

export function getBaseURL(): string {
  return process.env.REACT_APP_API_URL!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}
export function getCognitoBaseURL(): string {
  return process.env.REACT_APP_COGNITO_API_URL!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}

export const api = axios.create({ baseURL: getBaseURL() });
export const apiOssProxyInternal = axios.create({ baseURL: `${getBaseURL()}/oss-proxy/internal` });
export const apiOssProxyMapi = axios.create({ baseURL: `${getBaseURL()}/oss-proxy/mapi` });
export const apiCognito = axios.create({ baseURL: getCognitoBaseURL() });

export function setAuthTokenHeaders(token: string) {
  api.defaults.headers.common["Authorization"] = token;
  apiOssProxyInternal.defaults.headers.common["Authorization"] = token;
  apiOssProxyMapi.defaults.headers.common["Authorization"] = token;
  apiCognito.defaults.headers.common["Authorization"] = token;
}
