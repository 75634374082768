import {
  Table as BaseTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
} from "@chakra-ui/react";

export const Table = ({
  id,
  data,
  columns,
  linkColumns = [],
  css = {},
}: {
  id: string;
  data: { [key: string]: string | number | object | undefined }[];
  columns: string[];
  linkColumns?: string[];
  css?: object;
}) => {
  return (
    <TableContainer>
      <BaseTable size={"sm"} key={id} overflowX={"visible"} {...css}>
        <Thead>
          <Tr>
            {columns.map(c => (
              <Th>{c}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map(o => {
            const values = columns.map(c => o[c]);
            return (
              <Tr>
                {values.map((v, i) => {
                  if (linkColumns.includes(columns[i]) && typeof v === "string") {
                    return (
                      <Td>
                        <Link color="blue" href={v.toString()} isExternal>
                          {columns[i]}
                        </Link>
                      </Td>
                    );
                  } else {
                    return <Td>{typeof v === "object" ? JSON.stringify(v) : v ?? "undefined"}</Td>;
                  }
                })}
              </Tr>
            );
          })}
        </Tbody>
      </BaseTable>
    </TableContainer>
  );
};
