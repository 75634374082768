import { useNavigate } from "react-router-dom";
import { Box, Flex, Stack, VStack, Text, Code, Skeleton } from "@chakra-ui/react";
import { useAppContext } from "../contexts/app/index";
import { assignRoot, unassignRoot } from "../../api/customer";
import { setCxIsRoot } from "../shared/state-management";
import { CustomerMapEntry } from "../../domain/customer";
import { roleAdmin, areRolesIncluded } from "../../shared/access-control";
import { Accordion } from "../shared/Accordion";
import { TagBoolean } from "../shared/Tag";
import { ButtonBoolean } from "../shared/Button";

export default function HomePage() {
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();

  const rootActionRequiredRoles = [roleAdmin];

  async function handleIsRootChange(cxId: string, isRoot: boolean) {
    if (isRoot) {
      await unassignRoot(cxId);
    } else {
      await assignRoot(cxId);
    }
    setCxIsRoot({
      cxId,
      state,
      dispatch,
      isRoot: !isRoot,
    });
  }

  return (
    <Box>
      <VStack p={10} align="start">
        {!state.isLoaded ? (
          <Skeleton mt={5} h={100} w={"4xl"} />
        ) : (
          <>
            {state.userRoles.length > 0 ? (
              <Box>
                <Flex mb={5}>
                  <Text pt={3} fontWeight={"bold"}>
                    Customer list
                  </Text>
                </Flex>
                <Box>
                  <Accordion
                    id="customers"
                    entries={
                      Object.entries(state.customerMap ?? {})
                        .filter(
                          entry => entry[1] !== undefined && entry[1].rootCustomer.email !== null
                        )
                        .sort(
                          (a, b) =>
                            (a[1]?.rootCustomer.isRoot ? 0 : 1) -
                            (b[1]?.rootCustomer.isRoot ? 0 : 1)
                        ) as [string, object][]
                    }
                    getLabel={(payload: object) => {
                      const castPayload = payload as CustomerMapEntry;
                      return (
                        <Flex w={"full"} justifyContent={"space-between"}>
                          <Text>{castPayload.rootCustomer.email}</Text>
                          <Text>{castPayload.rootCustomer.id}</Text>
                        </Flex>
                      );
                    }}
                    labelOnClick={(payload: object) => {
                      const castPayload = payload as CustomerMapEntry;
                      navigate(`/customer/${castPayload.rootCustomer.id}`);
                    }}
                    getLabelActions={(payload: object) => {
                      const castPayload = payload as CustomerMapEntry;
                      const isRoot = (state.rootMap ?? {})[castPayload.rootCustomer.id];
                      if (isRoot !== undefined) {
                        return (
                          <>
                            {areRolesIncluded(rootActionRequiredRoles, state.userRoles) ? (
                              <ButtonBoolean
                                id="isRoot"
                                check={isRoot}
                                disableOnClick={false}
                                onClick={async () =>
                                  await handleIsRootChange(castPayload.rootCustomer.id, isRoot)
                                }
                                label="Root Status"
                                activeColor="green"
                              />
                            ) : (
                              <TagBoolean id="isRoot" check={isRoot} label={`Root Status`} />
                            )}
                            <TagBoolean
                              id="isActiveSub"
                              check={castPayload.rootCustomer.subscriptionStatus === "active"}
                              label={`Sub Active`}
                            />
                          </>
                        );
                      }
                    }}
                    getPanelInfo={(payload: object) => {
                      const castPayload = payload as CustomerMapEntry;
                      return (
                        <Box mt={10} pl={5}>
                          {castPayload.connectedEmails.length === 0 ? (
                            <Code>No associated users</Code>
                          ) : (
                            <Stack mb={2} direction="column">
                              <Code mb={1}>Associated Users</Code>
                              {castPayload.connectedEmails.map(email => {
                                return <Code key={email}>{email}</Code>;
                              })}
                            </Stack>
                          )}
                        </Box>
                      );
                    }}
                    getPanelInfoJsonExcludeKeys={["connectedEmails"]}
                  />
                </Box>
              </Box>
            ) : (
              <Text>
                You have not been added to a group to enable access. Please contact an admin.
              </Text>
            )}
          </>
        )}
      </VStack>
    </Box>
  );
}
