import { Box, Heading } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { get } from "lodash";
import { useState } from "react";
import { z } from "zod";
import { createOrUpdateFacility } from "../../../api/internal";
import { Facility, facilityCreateOrUpdateInternalSchema } from "../../../api/schemas/facility";
import { booleanOrUndefinedSchema } from "../../../api/schemas/shared";
import { FacilityType } from "../../../domain/facility";
import Constants from "../../shared/constants";
import DrawerForm from "../../shared/form/Drawer";
import { Input } from "../../shared/form/Input";
import { Select } from "../../shared/form/Select";
import useMetriportToast from "../../shared/toast";
import { captureAndDisplayError } from "../../shared/util";

const tooltipApproved = "Send (updates to) this facility to the HIE or not";
const tooltipActive =
  "Active/inactive at the HIE - doesn't impact the integration, just a property to be stored at the HIE";

const formSchema = facilityCreateOrUpdateInternalSchema
  .omit({
    id: true,
    cqApproved: true,
    cqActive: true,
    cwApproved: true,
    cwActive: true,
  })
  .merge(
    z.object({
      cqApproved: booleanOrUndefinedSchema,
      cqActive: booleanOrUndefinedSchema,
      cwApproved: booleanOrUndefinedSchema,
      cwActive: booleanOrUndefinedSchema,
    })
  );
type FormData = z.infer<typeof formSchema>;

export default function FacilityInternalForm({
  isOpen,
  onClose,
  onSubmit,
  cxId,
  currentFacility,
  isMapi,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (cxId: string, currentFacility: Facility | undefined, newFacility: Facility) => void;
  cxId: string;
  currentFacility: Facility | undefined;
  isMapi: boolean;
}) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const toast = useMetriportToast();

  const [isObo, setIsObo] = useState<boolean>(
    currentFacility ? currentFacility.cqType === FacilityType.initiatorOnly : false
  );

  const createOrUpdate = async (data: FormData) => {
    try {
      setIsSubmitting(true);
      let newFacility: Facility;
      const adjustedParams = {
        cqActive: isMapi
          ? undefined
          : data.cqActive !== "undefined"
          ? data.cqActive === "true"
          : undefined,
        cwActive: isMapi
          ? undefined
          : data.cwActive !== "undefined"
          ? data.cwActive === "true"
          : undefined,
        cqOboOid: isMapi
          ? undefined
          : data.cqType === FacilityType.initiatorAndResponder
          ? undefined
          : data.cqOboOid,
        cwOboOid: isMapi
          ? undefined
          : data.cwType === FacilityType.initiatorAndResponder
          ? undefined
          : data.cwOboOid,
        cqApproved: isMapi
          ? undefined
          : data.cqApproved !== "undefined"
          ? data.cqApproved === "true"
          : undefined,
        cwApproved: isMapi
          ? undefined
          : data.cwApproved !== "undefined"
          ? data.cwApproved === "true"
          : undefined,
      };
      if (currentFacility) {
        newFacility = await createOrUpdateFacility(cxId, {
          ...{
            ...currentFacility,
            address: undefined,
          },
          ...currentFacility.address,
          ...data,
          ...adjustedParams,
        });
      } else {
        newFacility = await createOrUpdateFacility(cxId, {
          ...data,
          ...adjustedParams,
        });
      }
      toast.success({
        duration: 10_000,
        title: `Facility saved.${
          adjustedParams.cqApproved || adjustedParams.cwApproved
            ? ` You have an HIE Approved. Please reload the page to see the new HIE facilities. It may take more than 30 seconds.`
            : ""
        }`,
      });
      onSubmit(cxId, currentFacility, newFacility);
    } catch (error) {
      // TODO Check for timeout and flag as warning (#1940)
      captureAndDisplayError({
        error,
        msg: "Failed to save facility",
        context: "createOrUpdate",
        captureMsg: `Component: FacilityInternalForm - ${
          currentFacility ? "update" : "create"
        } failed`,
        toast,
        extras: {
          cxId,
          facilityId: currentFacility?.id,
        },
      });
    }
    setIsSubmitting(false);
  };

  return (
    <DrawerForm<FormData>
      title={`${currentFacility ? "Edit" : "Create"} Facility`}
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onSubmit={createOrUpdate}
      onClose={onClose}
      resolver={zodResolver(formSchema)}
      defaultValues={{
        nameInMetriport: currentFacility?.name,
        npi: currentFacility?.npi,
        tin: currentFacility?.tin,
        cqOboOid: currentFacility?.cqOboOid === null ? undefined : currentFacility?.cqOboOid,
        cwOboOid: currentFacility?.cwOboOid === null ? undefined : currentFacility?.cwOboOid,
        addressLine1: currentFacility?.address.addressLine1,
        addressLine2: currentFacility?.address.addressLine2,
        city: currentFacility?.address.city,
        state: currentFacility?.address.state,
        zip: currentFacility?.address.zip,
        country: currentFacility?.address.country,
      }}
    >
      {({ register, reset, formState: { errors } }) => (
        <>
          <div hidden={isMapi}>
            <Heading as="h4" size="sm" mb={5}>
              Facility Type
            </Heading>
            <Select
              isRequired
              options={Constants.facilityOptions}
              value={isObo ? "OBO" : "Non-OBO"}
              onChange={change => {
                setIsObo(change.target.value === "OBO");
                reset();
              }}
              mb={12}
            />
          </div>
          <Heading as="h4" size="sm" mb={5}>
            Basic Info
          </Heading>
          <Input
            {...register("nameInMetriport")}
            isRequired
            label="Name in Metriport *"
            error={get(errors, "nameInMetriport")}
          />
          <Input
            type={"number"}
            {...register("npi")}
            isRequired
            label="National Provider Identifier (NPI) *"
            error={get(errors, "npi")}
            placeholder="Enter a valid 10-digit NPI. Ex: 1234567893"
          />
          <Input
            {...register("tin")}
            label="Tax Identification Number (TIN)"
            error={get(errors, "tin")}
          />
          <Box hidden={isMapi}>
            <Select
              {...register("cqApproved")}
              label="CQ Approved"
              tooltip={tooltipApproved}
              defaultValue={
                currentFacility?.cqApproved !== undefined && currentFacility?.cqApproved !== null
                  ? `${currentFacility?.cqApproved}`
                  : "false"
              }
              options={Constants.booleanAndUndefinedOptions}
              error={get(errors, "cqApproved")}
            />
            <Input
              {...register("cqType")}
              isRequired
              isReadOnly
              color={"grey"}
              label="CQ Type *"
              value={isObo ? "initiator_only" : "initiator_and_responder"}
              error={get(errors, "cqType")}
            />
            <Select
              {...register("cqActive")}
              label="CQ Active"
              tooltip={tooltipActive}
              defaultValue={
                currentFacility?.cqActive !== undefined && currentFacility?.cqActive !== null
                  ? `${currentFacility?.cqActive}`
                  : "false"
              }
              options={Constants.booleanAndUndefinedOptions}
              error={get(errors, "cqActive")}
            />
            <Input
              {...register("cqOboOid")}
              isRequired={!isMapi && isObo}
              label={isObo ? "CQ OBO OID *" : undefined}
              error={get(errors, "cqOboOid")}
              hidden={!isObo}
            />
            <Select
              {...register("cwApproved")}
              label="CW Approved"
              tooltip={tooltipApproved}
              defaultValue={
                currentFacility?.cwApproved !== undefined && currentFacility?.cwApproved !== null
                  ? `${currentFacility?.cwApproved}`
                  : "false"
              }
              options={Constants.booleanAndUndefinedOptions}
              error={get(errors, "cwApproved")}
            />
            <Input
              {...register("cwType")}
              isRequired
              isReadOnly
              color={"grey"}
              label="CW Type *"
              value={isObo ? "initiator_only" : "initiator_and_responder"}
              error={get(errors, "cwType")}
            />
            <Select
              {...register("cwActive")}
              label="CW Active"
              tooltip={tooltipActive}
              defaultValue={
                currentFacility?.cwActive !== undefined && currentFacility?.cwActive !== null
                  ? `${currentFacility?.cwActive}`
                  : isObo
                  ? "false"
                  : "true"
              }
              options={Constants.booleanAndUndefinedOptions}
              error={get(errors, "cwActive")}
            />
            <Input
              {...register("cwOboOid")}
              isRequired={!isMapi && isObo}
              label={isObo ? "CW OBO OID *" : undefined}
              error={get(errors, "cwOboOid")}
              hidden={!isObo}
            />
          </Box>
          <Heading as="h4" size="sm" pt={50}>
            Address
          </Heading>
          <Input
            {...register("addressLine1")}
            isRequired
            label="Address Line 1 *"
            error={get(errors, "addressLine1")}
          />
          <Input
            {...register("addressLine2")}
            label="Address Line 2"
            error={get(errors, "addressLine2")}
          />
          <Input {...register("city")} isRequired label="City *" error={get(errors, "city")} />
          <Select
            {...register("state")}
            isRequired
            label="State *"
            options={Constants.usStatesForAddress}
            placeholder="Select State"
            error={get(errors, "state")}
          />
          <Input {...register("zip")} isRequired label="Zip *" error={get(errors, "zip")} />
          <Input
            {...register("country")}
            isRequired
            isReadOnly
            color={"grey"}
            label="Country *"
            value={"USA"}
            error={get(errors, "country")}
          />
        </>
      )}
    </DrawerForm>
  );
}
